import { Injectable } from '@angular/core';
import { FirestoreService, CollectionQueryResultItem } from '../firestore/firestore.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { ChatObject, ChatMsg, OnlineStatusTypes, DocItem } from '@models/commons';
import { Observable, combineLatest } from 'rxjs';
import { UserService } from '../user/user.service';
import { take } from 'rxjs/operators';
import { QueryFn, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private colRef: string;

  constructor(
    private afDb: FirestoreService,
    private localStore: LocalStorageService,
    private userService: UserService
  ) {
    this.colRef = `companies/${this.localStore.getItemSync('companyId')}/services/${this.localStore.getItemSync('serviceId')}/chats`;
    this.localStore.getItems(['companyId', 'serviceId']).subscribe(arr => {
      this.colRef = `companies/${arr[0]}/services/${arr[1]}/chats`;
    });
  }

  public getChatId(userId1: string, userId2?: string): string {
    if (!userId2) {
      userId2 = this.localStore.getItemSync('userId');
    }

    return [userId1, userId2].sort().join('_');
  }

  public sendMsg(chat: ChatMsg, msgId: string): Promise<void> {
    return this.afDb.add(`${this.colRef}/${msgId}/messages`, chat);
  }

  public async createChatObjectForUsers(userId1: string, userId2?: string): Promise<string> {
    if (!userId2) {
      userId2 = this.localStore.getItemSync('userId');
    }

    // we need user info
    const [user1, user2] = await combineLatest([
      this.userService.getUserById(userId1),
      this.userService.getUserById(userId2)
    ]).pipe(take(1)).toPromise();

    if (!user1.UID || !user2.UID) {
      throw Error('Cannot init chat with user. UID is missing in one of the users');
    }

    const chatObj: ChatObject = {
      id: this.getChatId(userId1, userId2),
      recipientIds: [userId1, userId2],
      recipientUIDs: [user1.UID, user2.UID],
      recipientsInfo: {},
      lastMessage: '',
      lastMessageDate: null,
      log: this.afDb.createLog(),
      hasUnread: false,
    };

    [user1, user2].forEach(user => {
      chatObj.recipientsInfo[user.id] = {
        lastReadDate: this.afDb.getServerTime(),
        unreadCount: 0,
        userId: user.id,
        typingStatus: false
      };
    });

    return this.afDb.set(`${this.colRef}/${chatObj.id}`, chatObj).then(() => Promise.resolve(chatObj.id));
  }

  public updateChatObject(chatObj: DocItem): Promise<void> {
    return this.afDb.update(`${this.colRef}/${chatObj.id}`, chatObj);
  }

  public updateChatMessage(msg: ChatMsg, msgId: string): Promise<void> {
    return this.afDb.update(`${this.colRef}/${msgId}/messages/${msg.id}`, msg as DocItem);
  }

  public getChatObjectsUser(userId?: string): Observable<ChatObject[]> {
    if (!userId) {
      userId = this.localStore.getItemSync('userId');
    }

    return this.afDb.colWithIds$(this.colRef, ref => {
      return ref.where('recipientIds', 'array-contains', userId)
        .where('lastMessageDate', '>', new Date(2020, 1))
        .orderBy('lastMessageDate', 'desc');
    }) as Observable<ChatObject[]>;
  }

  public getChatObjectById(chatId: string): Observable<ChatObject> {
    return this.afDb.docWithId$(`${this.colRef}/${chatId}`);
  }

  public getChatMessages<T>(msgId: string, q?: QueryFn, addRef?: boolean): Observable<T[]> {
    return this.afDb.colWithIds$(`${this.colRef}/${msgId}/messages`, q, addRef) as Observable<T[]>;
  }

  public getUpcomingChatMessages(msgId: string, date: Date = new Date()): Observable<ChatMsg[]> {
    return this.getChatMessages(msgId, ref => {
      return ref.where('log.createdOn', '>', date).orderBy('log.createdOn', 'desc');
    });
  }

  public getPastChatMessages(
    msgId: string, date: Date = new Date(), limit: number, lastRef?: QueryDocumentSnapshot<ChatMsg>
  ): Observable<CollectionQueryResultItem<ChatMsg>[]> {
    return this.getChatMessages<CollectionQueryResultItem<ChatMsg>>(msgId, ref => {
      let refVal = ref.where('log.createdOn', '<=', date).orderBy('log.createdOn', 'desc');

      if (lastRef) {
        refVal = refVal.startAfter(lastRef);
      }

      return refVal.limit(limit);
    }, true) as Observable<CollectionQueryResultItem<ChatMsg>[]>;
  }
}
