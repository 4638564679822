// import { IObjectMap } from '@anchor-solutions-nl/translator-as';
import { Injectable } from '@angular/core';
import { QueryDocumentSnapshot, QueryFn } from '@angular/fire/compat/firestore';
import { DocItem, Service, User, UserCardPayload, UserHiddenTypes, UserReference } from '@models/commons';
import { Event } from 'functions/src/data-models/commons/model/event';
import { Observable, combineLatest, of } from 'rxjs';
import { combineAll, map, mergeAll } from 'rxjs/operators';
// import { TimestampDate } from 'timestamp-date';
import { CollectionQueryResultItem, FirestoreService } from '../firestore/firestore.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SortOrderTypes, UserService } from '../user/user.service';
import { EventFilter } from 'src/app/event-module/event-list/event-list.component';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
import { endOfDay, startOfDay } from 'date-fns';
import { SearchIndexService } from '../search-index/search-index.service';
import { chunk, flatten, unionBy } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  // private baseUrl = 'https://europe-west1-volunteer4work-dev.cloudfunctions.net/applicationApi/api/v1';
  // private timestampDate = new TimestampDate();
  private colRef: string;
  private userId: string = this.localStorage.getItemSync('userId');

  constructor(
    private localStorage: LocalStorageService,
    // private http: HttpClient,
    private afDb: FirestoreService,
    private userService: UserService,
    private searchService: SearchIndexService,
  ) {
    this.colRef = `/companies/${this.getCompanyId()}/events`;

    localStorage.getItem('companyId').subscribe(id => {
      this.colRef = `/companies/${id}/events`;
    });
  }

  // private getUri(path?: string): string {
  //   return this.baseUrl + path;
  // }

  // private handleApiRequest<T>(method: 'get' | 'post', path: string, data: IObjectMap<any>): Promise<T> {
  //   return new Promise(async (resolve, reject) => {
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       // 'Authorization': apiKey
  //     });

  //     const options: IObjectMap<any> = { headers };
  //     const url = this.getUri(path);
  //     data.companyId = this.getCompanyId();

  //     switch (method) {
  //       case 'get': {
  //         if (data) options.params = data;
  //         return resolve(this.http.get<T>(url, options).toPromise())
  //       }

  //       case 'post': return resolve(this.http.post<T>(url, data, options).toPromise());

  //       default: return reject('Invalid endpoint method call');
  //     }
  //   })
  // }

  private getCompanyId() {
    return this.localStorage.getItemSync('companyId');
  }

  // public async getEvents<T = any>(companyId: string, audience: string[]): Promise<T> {
  //   const res = await this.handleApiRequest<T>('post', `/orgs/${companyId}/events`, { audience });
  //   return this.timestampDate.parseStringToDate<T, T>(res);
  // }

  public getEvents(filters: EventFilter) {
    return this.afDb.colWithIdsNew$<Event>(this.colRef, () => [['isPassed', '==', false]],
      { limit: filters.limit, orderBy: [{ field: 'from', val: 'asc' }] }, true);
  }

  public getEventById<T = any>(eventId: string) {
    return this.afDb.docWithId$<T>(`${this.colRef}/${eventId}`);
  }


  public updateEvent(eventId: string, payload: DocItem) {
    return this.afDb.update(`${this.colRef}/${eventId}`, payload);
  }

  public getReferences<T>(ref: string, q: QueryFn, addRef?: boolean): Observable<T[]> {
    return this.afDb.colWithIds$(ref, q, addRef) as Observable<T[]>;
  }

  public getAttendeesReferences(user: User, starred: boolean, lastRef: QueryDocumentSnapshot<UserReference>, sort?: SortOrderTypes)  {
    
    const refCollection = `${this.userService.usersRef(this.userService.getRefPayloadForUser(user.id))}/references`;

    return this.getReferences<CollectionQueryResultItem<UserReference>>(refCollection, ref => {
      let refVal = ref.where('hidden', '==', sort === 'hidden' ? UserHiddenTypes.byMe : UserHiddenTypes.empty)
        .where('referencedUser.published', '==', true);

      const both = user.lookingFor.male && user.lookingFor.female;
      const none = !user.lookingFor.male && !user.lookingFor.female;

      if (none || !user.gender) {
        // looking for none??
        refVal = refVal.where('adviced.to.goto.zoo', '==', true);
      } else if (user.gender === 'male') {
        refVal = refVal.where('referencedUser.lookingFor.male', '==', true);

        if (!both) {
          const g = user.lookingFor.male ? 'male' : 'female';
          refVal = refVal.where('referencedUser.gender', '==', g);
        }
      } else if (user.gender === 'female') {
        refVal = refVal.where('referencedUser.lookingFor.female', '==', true);

        if (!both) {
          const g = user.lookingFor.male ? 'male' : 'female';
          refVal = refVal.where('referencedUser.gender', '==', g);
        }
      }

      if (starred) {
        refVal = refVal.where('starred', '==', true);
      }

      if (lastRef) {
        refVal = refVal.startAfter(lastRef);
      }

      // sort settings
      if (sort === 'created') {
        refVal = refVal.orderBy('log.createdOn', 'desc');
      } else if (sort === 'distance') {
        refVal = refVal.orderBy('referencedUser.distance', 'asc');
      }

      return refVal
    }, true).pipe(
      map(refs => {
        const userIds: string[] = refs.map(r => r.document.id);
        return this.userService.getUsersFromIds(userIds).pipe(
          map(users => {
            return users.map(u => {
              const docRef = refs.find(rp => rp.document.id === u.id);

              const card: UserCardPayload<QueryDocumentSnapshot<UserReference>> = {
                user: user,
                reference: docRef.document,
                docRef: docRef.ref
              };

              return card;
            });
          })
        );
      }),
      mergeAll()
    );
  }

  public searchEvent<T = any>(searchText: string, audience: string[]) {
    return this.afDb.colWithIds$<T>(this.colRef, ref => {
      return ref.where(`searchIndex.index.${searchText}`, '==', true)
        .where('audience', 'array-contains-any', audience)
    })
  }

  public filterEventByChip(categories: string[], audience: string[]) {
    return this.afDb.colWithIds$<Event>(this.colRef, (ref) => {
      return ref.where('categories', 'array-contains-any', categories)
        // .where('audience', 'array-contains-any', audience)
    }) as Observable<Event[]>;
  }


  public async getEventCount(category: { filter?: string }, eventFilter?: EventFilter) {
    return this.afDb.getCounts(this.colRef, () => {
      if (category) {
        return [['categories', 'array-contains', category.filter], ['isPassed', '==', false]];
      }
      return this.getFilters(eventFilter);
    });
  }

  public searchEvents(query: EventFilter): Observable<Event[]> {
    if (query.textFilter) {
      let where = this.getFilters(query);

      return new Observable((observer) => {
        this.searchService.searchCollectionIndex<Event>({
          searchText: query.textFilter,
          ref: this.colRef,
          where: where,
          // order
        }).then(events => {
          observer.next(events);
        });
      });
    }
  }

  public getAllEventsByFilter(query: EventFilter) {
    if (query.endDateFilter) {
      return combineLatest([
        this.afDb.colWithIdsNew$(this.colRef, () => this.getFilters(query)),
        this.afDb.colWithIdsNew$(this.colRef, () => this.getFilters(query, true))
      ]).pipe(map(data => unionBy(flatten<Event>(data), 'id')));
    }
    return this.afDb.colWithIdsNew$(this.colRef, () => this.getFilters(query));
  }

  public getEventsByFilters(query: EventFilter, limit?: number, lastOrFirstRef?: any, isForwardNavigation?: boolean, isReloadedContext?: boolean) {
    const options: Partial<QueryConstraintOptions<Event>> = {};

    return this.afDb.colWithIdsNew$<Event>(this.colRef, () => {

      if (lastOrFirstRef) {
        options.orderBy = [{ field: 'from', val: 'asc' }]
        if (isReloadedContext) {
          options.startAt = lastOrFirstRef;
          options.limit = limit || 30;
        } else {
          if (isForwardNavigation) {
            options.startAfter = lastOrFirstRef;
            options.limit = limit || 30;
          } else {
            options.endBefore = lastOrFirstRef;
            options.limitToLast = limit || 30;
          }
        }
      } else {
        options.limit = limit || 30
      }
      return this.getFilters(query);
    }, options, true)
  }

  private getFilters(query: EventFilter, queryTo: boolean = false) {
    const where: Where[] = [];

    if (!queryTo && query.startDateFilter) {
      where.push(['from', '>=', startOfDay(query.startDateFilter)], ['from', '<=', endOfDay(query.endDateFilter || query.startDateFilter)]);
    } else if (queryTo && query.endDateFilter) {
      where.push(['to', '>=', startOfDay(query.startDateFilter)], ['to', '<=', startOfDay(query.endDateFilter)], ['multiDay', '==', true]);
    } else {
      if (query.categoryFilter) {
        where.push(['categories', 'array-contains', query.categoryFilter])
      }
      if (query.municipalityFilter) {
        where.push(['area.municipality.code', '==', query.municipalityFilter]);
      }
      if (query.districtFilter) {
        where.push(['area.district.code', '==', query.districtFilter]);
      }
      where.push(['isPassed', '==', query.showPastFilter]);
    }
    return where;
  }

  public getEventsByIds(ids: string[]) {
    ids = ids.filter(id => !!id);
    if (ids.length) {
      return combineLatest(chunk(ids, 30).map(ids => {
        return this.afDb.colWithIdsNew$<Event>(this.colRef, () => [['id', 'in', ids]]);
      })).pipe(map(data => flatten(data)));
    } else return of([]) as Observable<Event[]>
  }

}
