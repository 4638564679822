<div class="img_modal_body" fxLayout="column" fxFlex="stretch">
  <div fxLayout="row" fxLayoutAlign="end center" class="ctrls">
    <button mat-icon-button (click)="dialogRef.close()">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" fxFlex="stretch" class="img-container">
    <img [src]="data.url" alt="image preview" class="img" [style]="getStyle()">
  </div>
</div>