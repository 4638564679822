export enum UserStatusTypes {
    created = 1,
    invited,
    started, // this option has not been used in the application
    active
}

export enum UserHiddenTypes {
    empty = 1,
    byMe,
    byOther
}

export enum AuthPhase {
    acceptTerms = 1,
    mobileNumber,
    password
}