import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { filter, map, mergeAll } from 'rxjs/operators';
import { AppMenu, MenuItem } from 'src/app/shared/data-models/interface';
import { Title } from '@angular/platform-browser';
import { LabelService } from '../label/label.service';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { NoticeBoardService } from '../notice-board/notice-board.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AuthService } from '../auth/auth.service';
import { ChatObject, UserNoticeBoardRef } from '@models/commons';
import { ChatService } from '../chat/chat.service';

@Injectable({
    providedIn: 'root'
})
export class RouterService {
    public loading$: BehaviorSubject<boolean>;

    private routeState$: BehaviorSubject<AppMenu>;
    private labels: ILabelsProvider = this.labelService.defaultProvider();
    private unseenNoticeBoardItems: number;
    private unreadChatMessages: number;

    constructor(
        private titleService: Title,
        private labelService: LabelService,
        private noticeboardService: NoticeBoardService,
        private localStore: LocalStorageService,
        private authService: AuthService,
        private chatService: ChatService,
    ) {
        this.routeState$ = new BehaviorSubject(undefined);
        this.loading$ = new BehaviorSubject(false);
        this.loadUtilsData();
    }

    private loadUtilsData(): void {
        const sub = combineLatest([
            this.labelService.getLabels('app-menu'),
            this.authService.getAuthUser().pipe(
                map(auth => {
                    if (auth) {
                        return combineLatest([
                            this.noticeboardService.getUserNoticeBoardRefByUserId(),
                            this.chatService.getChatObjectsUser(),
                        ]);
                    } else {
                        return of([]);
                    }
                }),
                mergeAll()
            )
        ]).subscribe((arr: [ILabelsProvider, [UserNoticeBoardRef, ChatObject[]]]) => {
            this.labels = arr[0];
            const board = arr[1][0];
            const chats = arr[1][1];
            const currentUserId = this.localStore.getItemSync('userId');

            if (board) {
                this.unseenNoticeBoardItems = board.items.reduce((prev, cur) => {
                    return prev + (!cur.seen ? 1 : 0);
                }, 0);
            }

            this.unreadChatMessages = (chats || []).reduce((prev, cur) => {
                return prev + cur.recipientsInfo[currentUserId].unreadCount;
            }, 0);
            const curMenu = this.routeState$.getValue();
            if (curMenu) {
                this.updateAppMenu({ title: curMenu.title });
            }
        });

        this.authService.addAuthSubscriptions(sub);
    }

    public updateBrowserTitle(title: string): void {
        this.titleService.setTitle(title);
    }

    public getAppMenu(): Observable<AppMenu> {
        return this.routeState$.pipe(
            filter(a => !!a)
        );
    }

    public showLoading() {
        this.loading$.next(true);
    }

    public hideLoading() {
        this.loading$.next(false);
    }

    public updateAppMenu(menu: AppMenu, patch?: boolean, updateBrowser?: boolean): void {
        let defaultMenu: AppMenu;

        if (patch) {
            defaultMenu = this.routeState$.getValue();
        } else {
            defaultMenu = this.defaultMenu();
        }

        const updated = Object.assign({}, defaultMenu, menu);
        this.routeState$.next(updated);

        if (updateBrowser) {
            this.updateBrowserTitle(`${updated.title} | Vind Gezelschap`);
        }
    }

    private defaultMenu(): AppMenu {
        const config: AppMenu = {
            showBackButton: false,
            sidebarItems: this.getSideBarItems(),
            sidebarMoreItems: this.getSideBarMoreItems()
        };

        return config;
    }

    private getSideBarMoreItems(): MenuItem[] {
        const res: MenuItem[] = [
            { title: this.labels.about, route: 'info/about', icon: 'coffee' },
            { title: this.labels.help, route: 'info/help', icon: 'help' },
            { title: this.labels.logout, id: 'logout', icon: 'logout' }
        ];

        return res;
    }

    private getSideBarItems(): MenuItem[] {
        const res: MenuItem[] = [
            { title: this.labels.people, route: 'people', icon: 'account-multiple' },
            { title: this.labels.notice_board, route: 'notice-board', icon: 'pin', badgeValue: this.unseenNoticeBoardItems },
            { title: this.labels.profile, route: 'profile', icon: 'face-profile' },
            { title: this.labels.messages, route: 'messages', icon: 'inbox', badgeValue: this.unreadChatMessages },
            { title: this.labels.settings, route: 'settings', icon: 'settings' }
        ];

        return res;
    }
}
