import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { UserServiceMap } from '@models/commons';
import { PushNotificationSubscriptionPayload } from 'functions/src/data-models/commons/model/notification.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import { FirestoreService } from '../firestore/firestore.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({ providedIn: 'root' })
export class PwaService {
  constructor(
    private swUpdate: SwUpdate,
    private dialogService: DialogService,
    private swPush: SwPush,
    private utilitiesService: UtilitiesService,
    private db: FirestoreService,
    private storageService: LocalStorageService,
    private authService: AuthService,
  ) { }

  public init(): void {
    if (this.swPush.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        if (event.type == 'VERSION_READY') {
          this.utilitiesService.delay(3000).subscribe(() => {
            this.dialogService.showToast('New update available', 'Reload', 100000).then(async () => {
              const keys = await window.caches.keys();
              for (const key of keys) {
                await window.caches.delete(key);
              }

              window.location.reload();
            });
          });
        }
      });

      this.swPush.notificationClicks.subscribe(
        event => {
          const url = event.notification.data.url || '/dashboard'.match('');
          const UID = event.notification.data.uid;

          this.utilitiesService.delay(700).subscribe(() => {
            this.authService.getAuthUser().subscribe(auth => {
              window.focus();

              if (auth) {
                if (auth.uid === UID) {
                  // 
                  const serviceMap: UserServiceMap = {
                    userId: event.notification.data.userId,
                    companyId: event.notification.data.companyId,
                    serviceId: event.notification.data.serviceId,
                  }

                  this.storageService.setAuthStorageItems(serviceMap);
                  this.utilitiesService.delay(500).subscribe(() => {
                    window.location.href = url;
                  });
                } else {
                  // just goto dashboard of already logged in account
                  window.location.href = '/dashboard';
                }
              } else {
                window.location.href = `https://vindgezelschap.nl/auth?redirect_url=${url}`;
              }
            });
          });
        },
        error => {
          // handle error
          console.error(error);
        }
      );
    }
  }

  public async subscribeToNotifications(): Promise<PushSubscriptionJSON> {
    return this.swPush.requestSubscription({
      serverPublicKey: environment.VAPID_PUBLIC_KEY,
    }).then(s => s.toJSON());
  }

  public getPushNotificationsDoc(uid: string): Observable<PushNotificationSubscriptionPayload> {
    const docPath = `push-notifications/${uid}`;
    return this.db.docWithId$<PushNotificationSubscriptionPayload>(docPath);
  }

  public saveSubscriptionPayload(data: PushNotificationSubscriptionPayload): Promise<void> {
    return this.db.set(`push-notifications/${data.id}`, data);
  }
}
