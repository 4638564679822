import { NgModule } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { RouterService } from './router/router.service';
import { UserService } from './user/user.service';
import { UtilitiesService } from './utilities/utilities.service';
import { LocalStorageService } from './local-storage/local-storage.service';
import { FirestoreService } from './firestore/firestore.service';
import { CloudFunctionService } from './fn/fn.service';
import { DialogService } from './dialog/dialog.service';
import { FileService } from './file/file.service';
import { LayoutService } from './layout/layout.service';
import { ModalNavigatorService } from './modal-navigator/modal-navigator.service';
import { LocationService } from './location/location.service';
import { CompanyService } from './company/company.service';
import { ServiceService } from './service/service.service';
import { SearchIndexService } from './search-index/search-index.service';
import { ChatService } from './chat/chat.service';
import { NoticeBoardService } from './notice-board/notice-board.service';
import { StateService } from './state/state.service';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    AuthService,
    ChatService,
    CloudFunctionService,
    CompanyService,
    DialogService,
    FileService,
    FirestoreService,
    LayoutService,
    LocalStorageService,
    LocationService,
    ModalNavigatorService,
    NoticeBoardService,
    RouterService,
    SearchIndexService,
    ServiceService,
    StateService,
    UserService,
    UtilitiesService
  ]
})
export class AppServiceModule { }
