import { Component, ContentChild, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { ModalNavigatorService } from '@core/services/modal-navigator/modal-navigator.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
    isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);
    @ContentChild('modal_header', { static: true }) header: any;
    @ContentChild('modal_footer', { static: true }) footer: any;
    @Input() isLoading: boolean;
    @Input() headerControl: boolean;
    @Input() hideBackButton: boolean;
    @Input() hideCloseButton: boolean;
    @Input() headerBgColor: string;
    @Input() hideHeader: boolean;

    constructor(
        private matDialogRef: MatDialogRef<any>,
        private breakpointObserver: BreakpointObserver,
        private modalNavigatorService?: ModalNavigatorService,
        @Inject(MAT_DIALOG_DATA) public data?: any
    ) {
        const smallDialogSubscription = this.isExtraSmall.subscribe((result) => {
            if (result.matches) {
                matDialogRef.updateSize(
                    matDialogRef._containerInstance._config.width,
                    matDialogRef._containerInstance._config.height
                );
            }
        });

        matDialogRef.afterClosed().subscribe(() => {
            smallDialogSubscription.unsubscribe();
        });

        if (this.isStackedModal()) {
            modalNavigatorService.push(this.data.modalId, matDialogRef);
            matDialogRef.disableClose = true;
        }
    }

    private isStackedModal(): boolean {
        return !!(this.data && this.data.modalId && this.modalNavigatorService);
    }

    public showBackButton(): boolean {
        return !this.hideBackButton && this.isStackedModal() && this.modalNavigatorService.size() > 1;
    }

    public closeAllModals() {
        if (this.isStackedModal()) {
            this.modalNavigatorService.closeAll();
        } else {
            this.matDialogRef.close();
        }
    }

    public closeModal(result?: any) {
        if (this.isStackedModal()) {
            this.modalNavigatorService.pop(result);
        } else {
            this.matDialogRef.close(result);
        }
    }
}
