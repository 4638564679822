import { Injectable } from '@angular/core';
import { ISearchIndex } from '@models/commons';
import { cloneDeep, uniqBy } from "lodash";
import { FirestoreService } from '../firestore/firestore.service';
import { SpecialCharacters } from 'functions/src/data-models/commons/model/special-chars.model';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';
export interface ISearchQuery {
    searchText: string;
    ref: string;
    order?: string[];
    limit?: number;
    where?: any[][];
}
@Injectable({
    providedIn: 'root'
})
export class SearchIndexService {
    constructor(
        private firestoreService: FirestoreService
    ) { }

    private getDynamicTokens(prop: string, index: number, props: string[], scan: any, generatedValues: any[]) {
        if (prop) {
            if (prop.indexOf('{') >= 0) {
                Object.keys(scan).forEach(key => {
                    this.getDynamicTokens(props[index + 1], index + 1, props, scan[key], generatedValues);
                });
            } else {
                this.getDynamicTokens(props[index + 1], index + 1, props, scan[prop] || {}, generatedValues);
            }
        } else if (typeof scan === 'string') {
            generatedValues.push(scan);
        }
    }

    private getTokenValues(data: any, property: string): string[] {
        if (property.indexOf('{') >= 0) {
            const generatedValues = [];

            const props = property.split('.');
            this.getDynamicTokens(props[0], 0, props, Object.assign(data), generatedValues);
            return generatedValues;
        } else {
            const values: string = property.split('.').reduce((accumulator, currentProperty) => {
                return accumulator[currentProperty] || {};
            }, data) || '';

            if (typeof values === 'string') {
                return values.includes(' ') ? [values].concat(values.split(' ')) : [values];
            } else {
                return [];
            }
        }
    }

    public updateSearchIndex<T>(data: { searchIndex: ISearchIndex; }): T {
        if (data.searchIndex) {
            const properties: string[] = data.searchIndex.properties;
            const index = {};

            properties.forEach((property) => {
                this.getTokenValues(data, property).forEach((res: string) => {
                    try {
                        const tokens = res.toLowerCase().split('');
                        let searchSpace = '';

                        tokens.forEach((token) => {
                            searchSpace += token;
                            index[searchSpace] = true;
                        });
                    } catch (e) { }
                });
            });

            data.searchIndex.index = index;
        }

        return data as any;
    }

    public searchCollectionIndex<T>(searchQuery: ISearchQuery): Promise<T[]> {
        return new Promise(async (resolve) => {
            searchQuery.searchText = SpecialCharacters.fromSpecial(searchQuery.searchText.toLowerCase().trim());
            const docsObj: any = {};

            if (searchQuery.searchText.length > 0) {
                // here we break down the words and search individually
                const searchTerms = searchQuery.searchText.split(' ')
                    .map(text => text.trim())
                // .filter(text => {
                //     const result = !!text
                //         && !text.includes('.')
                //         && !text.includes('de')
                //         && !text.includes('der')
                //         && !text.includes('den')
                //         && !text.includes('van')
                //     console.log(text);
                //     return result
                // }
                // );

                for (const term of searchTerms) {
                    const splitQuery = { ...searchQuery };
                    splitQuery.searchText = term;
                    splitQuery.where = cloneDeep(splitQuery.where);
                    let docs: any[] = [];
                    try {
                        docs = await this.getOptionsFromDB(splitQuery);
                    } catch (e) {
                        console.log('Error here ', e);
                    }

                    docs.forEach((doc) => {
                        docsObj[doc.id] = doc;
                    });
                }
            }

            resolve(Object.keys(docsObj).map(id => docsObj[id]));
        });
    }

    public getOptionsFromDB(splitQuery: ISearchQuery): Promise<any[]> {
        const options: QueryConstraintOptions<ISearchIndex> = {};
        return this.firestoreService.colWithIdsNoCache(splitQuery.ref, () => {
            const clause: Where[] = [[`searchIndex.index.${splitQuery.searchText}`, '==', true]];

            while (splitQuery.where && splitQuery.where.length > 0) {
                const where = splitQuery.where.shift();
                clause.push([where[0], where[1], where[2]]);
            }

            if (splitQuery.order) {
                options.orderBy = [{ field: splitQuery.order[0], val: splitQuery.order[1] as any }];
            }

            if (splitQuery.limit) {
                options.limit = splitQuery.limit;
            }
            return clause;
        }, options, true);
    }

    public searchListBySearchIndex<T>(list: T[], searchText: string): T[] {
        searchText = SpecialCharacters.fromSpecial(searchText.toLowerCase().trim());

        if (searchText.length > 0) {
            const result: T[] = [];

            // here we break down the words and search individually
            const searchTerms = searchText.split(' ')
                .map(text => text.trim())
                .filter(text => !!text);

            list.forEach((item: any) => {
                for (const text of searchTerms) {
                    if (item.searchIndex.index[text]) {
                        result.push(item);
                        break;
                    }
                }
            });

            // @ts-ignore
            return this.orderItemsBySearchRelevance(uniqBy(result, 'id'), searchText);
        } else {
            return list;
        }
    }

    public orderItemsBySearchRelevance<T>(list: T[], text: string): T[] {
        // and query
        const searchParts: string[] = text.toLocaleLowerCase().split(' ')
            .map(textPart => textPart.trim())
            .filter(textPart => !!textPart);

        if (searchParts.length > 1) {
            list.forEach((item: any) => {
                let countMatch = 0
                for (const part of searchParts) {
                    if (item.searchIndex.index[part]) {
                        countMatch = countMatch + 1
                    }
                }
                item.countMatch = countMatch

            });

            // @ts-ignore
            return uniqBy(list, 'id');
        } else {
            return list;
        }
    }
}
