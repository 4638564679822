import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    public screenResize$: Observable<MediaChange[]>;

    constructor(
        private mediaObserver: MediaObserver
    ) {
        this.setScreenResizeHandler();
    }

    private setScreenResizeHandler() {
        this.screenResize$ = this.mediaObserver.asObservable();
    }

    public onScreenMatches(alias: string): Observable<boolean> {
        return this.screenResize$.pipe(
            map((media: MediaChange[]) => {
                for (const m of media) {
                    if (m.mqAlias === alias) {
                        return true;
                    }
                }

                return false;
            })
        );
    }
}
