import { Injectable } from '@angular/core';
import { SearchIndexService } from '../search-index/search-index.service';
import { Observable, from, combineLatest } from 'rxjs';
import { User, UserReference, UserHiddenTypes, UserCardPayload, UserServiceMap, ISearchIndex, ISearchUser, UserData, DocItem } from '@models/commons';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { FirestoreService, CollectionQueryResultItem } from '../firestore/firestore.service';
import { map, mergeAll, pluck, take } from 'rxjs/operators';
import { QueryFn, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { CloudFunctionService } from '../fn/fn.service';
import { orderBy, values } from 'lodash';

export type SortOrderTypes = 'firstName' | 'random' | 'created' | 'distance' | 'hidden';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private colRef: string;

    constructor(
        private localStore: LocalStorageService,
        private afDb: FirestoreService,
        private searchIndexService: SearchIndexService,
        private fnService: CloudFunctionService
    ) {
        this.colRef = this.usersRef(this.getRefPayloadForUser());

        this.localStore.getItems(['companyId', 'serviceId', 'userId']).subscribe(async (arr) => {
            this.colRef = this.usersRef(this.getRefPayloadForUser());
        });

        // this.afDb.colWithIds$('userData').pipe(take(1)).subscribe((usd: UserData[]) => {
        //     usd.forEach(ud => {
        //         values(ud.companies).forEach(c => {
        //             values(c).forEach(s => {
        //                 const ref = `companies/${s.companyId}/services/${s.serviceId}/users/${s.userId}/references`;

        //                 this.afDb.colWithIds$(ref).pipe(take(1)).subscribe((refs: UserReference[]) => {
        //                     const affected: UserReference[] = refs.map(r => {
        //                         if (!r.log) {
        //                             r.log = this.afDb.createLog();
        //                         }

        //                         if (r.log.createdOn) {
        //                             if (r.log.createdOn.seconds) {
        //                                 r.log.createdOn = new Date(r.log.createdOn.seconds*1000) as any;
        //                             }
        //                         } else {
        //                             r.log.createdOn = r.log.updatedOn;
        //                         }

        //                         return r;
        //                     });

        //                     this.afDb.batchUpdate(affected, ref);
        //                 });
        //             });
        //         });
        //     });

        //     this.afDb.docsFromRefs$(refIds).pipe(take(1)).subscribe(users => {
        //         users.filter(u => !!u).forEach((user: User) => {
        //             if (!user.log) {
        //                 user.log = this.afDb.createLog();
        //             }

        //             if (user.log.createdOn) {
        //                 if (user.log.createdOn.seconds) {
        //                     user.log.createdOn = new Date(user.log.createdOn.seconds*1000) as any;
        //                 }
        //             } else {
        //                 user.log.createdOn = user.log.updatedOn;
        //             }

        //             this.afDb.update(`companies/${user.companyId}/services/${user.serviceId}/users/${user.id}`, user);
        //         });
        //     });
        // });
    }

    private defaultIndex(): ISearchIndex {
        return {
            properties: ['interests.{{i}}', 'firstName', 'lastName'],
            index: {}
        };
    }

    public replaceUser(user: User): Promise<void> {
        user.searchIndex = user.searchIndex || this.defaultIndex();
        user = this.searchIndexService.updateSearchIndex(user);
        return this.afDb.set(this.usersRef(this.getRefPayloadForUser(user.id)), user);
    }

    public updateUser(user: User): Promise<void> {
        user.searchIndex = user.searchIndex || this.defaultIndex();
        user = this.searchIndexService.updateSearchIndex(user);
        return this.afDb.update(this.usersRef(this.getRefPayloadForUser(user.id)), user);
    }

    public getCurrentUserId(): string {
        return this.localStore.getItemSync('userId');
    }

    public getCurrentUser(): Observable<User> {
        const userId = this.getCurrentUserId();
        return this.getUserById(userId);
    }

    public getUserById(userId: string): Observable<User> {
        return this.afDb.docWithId$(`${this.colRef}/${userId}`);
    }

    public getUserFromRef(ref: string): Observable<User> {
        return this.afDb.docWithId$(ref);
    }

    public getUsersFromMap(sMaps: UserServiceMap[]): Observable<User[]> {
        return combineLatest(sMaps.map(m => {
            return this.getUserFromRef(this.usersRef(m));
        }));
    }

    public getUserByEmail(email: string): Observable<User> {
        return this.afDb.colWithIds$<User>(this.colRef, ref => {
            return ref.where('email', '==', email).limit(1);
        }).pipe(
            map(users => users[0] as User)
        );
    }

    public getUsers(q?: QueryFn): Observable<User[]> {
        return this.afDb.colWithIds$(this.colRef, q) as Observable<User[]>;
    }

    public getReferences<T>(ref: string, q: QueryFn, addRef?: boolean): Observable<T[]> {
        return this.afDb.colWithIds$(ref, q, addRef) as Observable<T[]>;
    }

    public updateReferenceById(referenced: string, payload: DocItem, referencee?: string): Promise<void> {
        if (!referencee) {
            referencee = this.getCurrentUserId();
        }

        const usersRef = this.usersRef(this.getRefPayloadForUser());
        return this.afDb.update(`${usersRef}/${referencee}/references/${referenced}`, payload);
    }

    public getReferenceById(referenced: string, referencee?: string): Observable<UserReference> {
        if (!referencee) {
            referencee = this.getCurrentUserId();
        }

        const usersRef = this.usersRef(this.getRefPayloadForUser());
        return this.afDb.docWithId$(`${usersRef}/${referencee}/references/${referenced}`);
    }

    public getMyReferenceFromUser(referencee: string): Observable<UserReference> {
        const usersRef = this.usersRef(this.getRefPayloadForUser());
        return this.afDb.docWithId$(`${usersRef}/${referencee}/references/${this.getCurrentUserId()}`);
    }

    public getUserCardPayloadForUser(referenced: string, referencee?: string): Observable<UserCardPayload<void>> {
        if (!referencee) {
            referencee = this.getCurrentUserId();
        }

        const userRef = this.usersRef(this.getRefPayloadForUser(referenced));
        return combineLatest([
            this.getUserFromRef(userRef),
            this.getReferenceById(referenced, referencee)
        ]).pipe(
            map(items => {
                const result: UserCardPayload<void> = {
                    user: items[0],
                    reference: items[1]
                };

                return result;
            })
        );
    }

    public getRefPayloadForUser(userId?: string): UserServiceMap {
        return {
            companyId: this.localStore.getItemSync('companyId'),
            serviceId: this.localStore.getItemSync('serviceId'),
            userId: userId
        };
    }

    public usersRef(data: UserServiceMap): string {
        let ref = `companies/${data.companyId}/services/${data.serviceId}/users`;

        if (data.userId) {
            ref += `/${data.userId}`;
        }

        return ref;
    }

    public getUsersFromIds(userIds: string[]): Observable<User[]> {
        return this.afDb.docsFromId$(this.colRef, userIds);
    }

    public searchForUsers(payload: ISearchUser): Observable<UserCardPayload<void>[]> {
        return from(this.fnService.searchUsers(payload)) as any; // .pipe(pluck('data'));
    }

    public getUserServiceMaps(userData: UserData): UserServiceMap[] {
        let profiles: UserServiceMap[] = [];

        values(userData.companies).forEach(companies => {
            values(companies).forEach(service => {
                profiles.push(service);
            })
        });

        return profiles;
    }

    public getUsersForProfile(
        user: User, starred: boolean, lastRef: QueryDocumentSnapshot<UserReference>, sort?: SortOrderTypes
    ): Observable<UserCardPayload<QueryDocumentSnapshot<UserReference>>[]> {
        const refCollection = `${this.usersRef(this.getRefPayloadForUser(user.id))}/references`;

        return this.getReferences<CollectionQueryResultItem<UserReference>>(refCollection, ref => {
            let refVal = ref.where('hidden', '==', sort === 'hidden' ? UserHiddenTypes.byMe : UserHiddenTypes.empty)
                .where('referencedUser.published', '==', true);

            const both = user.lookingFor.male && user.lookingFor.female;
            const none = !user.lookingFor.male && !user.lookingFor.female;

            if (none || !user.gender) {
                // looking for none??
                refVal = refVal.where('adviced.to.goto.zoo', '==', true);
            } else if (user.gender === 'male') {
                refVal = refVal.where('referencedUser.lookingFor.male', '==', true);

                if (!both) {
                    const g = user.lookingFor.male ? 'male' : 'female';
                    refVal = refVal.where('referencedUser.gender', '==', g);
                }
            } else if (user.gender === 'female') {
                refVal = refVal.where('referencedUser.lookingFor.female', '==', true);

                if (!both) {
                    const g = user.lookingFor.male ? 'male' : 'female';
                    refVal = refVal.where('referencedUser.gender', '==', g);
                }
            }

            if (starred) {
                refVal = refVal.where('starred', '==', true);
            }

            if (lastRef) {
                refVal = refVal.startAfter(lastRef);
            }

            // sort settings
            if (sort === 'created') {
                refVal = refVal.orderBy('log.createdOn', 'desc');
            } else if (sort === 'distance') {
                refVal = refVal.orderBy('referencedUser.distance', 'asc');
            }

            return refVal.limit(100);
        }, true).pipe(
            map(refs => {
                const userIds: string[] = refs.map(r => r.document.id);
                return this.getUsersFromIds(userIds).pipe(
                    map(users => {
                        if (sort === 'firstName') {
                            users = orderBy(users, ['firstName'], ['asc'])
                        }
                        return users.filter(u => !!u).map(u => {
                            const docRef = refs.find(rp => rp.document.id === u.id);

                            const card: UserCardPayload<QueryDocumentSnapshot<UserReference>> = {
                                user: u,
                                reference: docRef.document,
                                docRef: docRef.ref
                            };

                            return card;
                        });
                    })
                );
            }),
            mergeAll()
        );
    }
}
