import { ErrorHandler, Inject, Injectable } from '@angular/core';
import Rollbar from 'rollbar';

@Injectable({
  providedIn: 'root'
})
export class RollbarErrorHandler extends ErrorHandler {

  private rollbar: Rollbar;

  constructor() { 
    super();
  }

  handleError(err: any): void {
    // this.rollbar.error(err.originalError || err);
    this.rollbar = new Rollbar({
      accessToken: '645a4bd96780493b9c549388ff3c65e0',
      captureUncaught: true,
      captureUnhandledRejections: true,
      environment: 'live',
      captureDeviceInfo: true,
      enabled: true,
      payload: {
        userData: {
          serviceId: localStorage.getItem('serviceId'),
          companyId: localStorage.getItem('companyId'),
        },
        person: {
          id: localStorage.getItem('userId'),
        },
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true,
          }
        }
      }
    });
    this.rollbar.error(
      err.originalError || err,
      localStorage
    );
    
    super.handleError(err);
  }

}
