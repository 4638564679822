import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeAll, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { chunk, isEqual } from 'lodash';
import { DocumentLog, ObjectMap, DocItem, User } from '@models/commons';
import { LocalStorageService } from '../local-storage/local-storage.service';
import {
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  QueryDocumentSnapshot,
  AngularFirestore,
  QueryFn
} from '@angular/fire/compat/firestore';
import { TimestampDate } from 'timestamp-date';
import firebase from 'firebase/compat/app';
import { differenceInSeconds } from 'date-fns';
import { AuthService } from '../auth/auth.service';
import { collection, CollectionReference, DocumentData, Firestore, getCountFromServer, getFirestore, query, QueryConstraint, where, Query as QueryNew, collectionSnapshots, DocumentReference, doc as document, docData, getDocsFromServer, orderBy, startAfter, endBefore, limit, startAt, limitToLast, collectionGroup } from '@angular/fire/firestore';
import { QueryConstraintOptions, Where } from '@shared/model/firestore';

export type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
export type DocPredicate<T> = string | AngularFirestoreDocument<T>;
export interface CollectionQueryResultItem<T> {
  ref: QueryDocumentSnapshot<T>;
  document: T;
}

interface OnlinePayload {
  companyId: string;
  userId: string;
  serviceId: string;
  lastActive: Date;
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private BATCH_SIZE = 500;
  private timestampDate = new TimestampDate();
  private online$: BehaviorSubject<OnlinePayload> = new BehaviorSubject(undefined);
  private firestore: Firestore;
  
  constructor(
    private afs: AngularFirestore,
    private localStore: LocalStorageService,
    private authService: AuthService,
  ) {
    this.firestore = getFirestore();
    let currentUser: User;

    this.online$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map(val => {
        return combineLatest([
          this.authService.getAuthUser(),
          of(val),
        ]);
      }),
      mergeAll(),
      filter(arr => {
        const auth = arr[0];

        return !!auth;
      }),
      map(arr => arr[1]),
      map(val => {
        if (!val) {
          setTimeout(() => {
            this.handleOnlineStatus(true);
          }, 3000);
          return [];
        }

        const ref = `companies/${val.companyId}/services/${val.serviceId}/users/${val.userId}`;
        return combineLatest([
          currentUser ? of(currentUser) : this.docWithId$<User>(ref).pipe(take(1)),
          of(val),
          of(ref),
        ]);
      }),
      mergeAll(),
    ).subscribe(async (arr) => {
      if (arr.length === 0) {
        return;
      }

      currentUser = arr[0];
      const [val, ref] = [arr[1], arr[2]];

      if (!isEqual(val.lastActive, currentUser.lastActive)) {
        this.update(ref, { log: currentUser.log, lastActive: val.lastActive, id: currentUser.id });
      }
    });
  }

  private handleOnlineStatus(action: boolean): void {
    const current: Date = this.online$.getValue()?.lastActive;
    const timeLapseInSeconds = 60;
    const userId = localStorage.getItem('userId');
    const companyId = localStorage.getItem('companyId');
    const serviceId = localStorage.getItem('serviceId');

    // do this only if user is identified
    if (userId && companyId && serviceId) {
      if (action) {
        const now = new Date();

        if (!current || differenceInSeconds(now, current) >= timeLapseInSeconds) {
          const payload: OnlinePayload = {
            companyId, serviceId, userId,
            lastActive: now,
          };

          this.online$.next(payload);
        }
      } else if (current) {
        this.online$.next(null);
      }
    }
  }

  private col<T>(ref: CollectionPredicate<T>, queryfn?: QueryFn): AngularFirestoreCollection<T> {
    this.handleOnlineStatus(true);
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryfn) : ref;
  }

  private doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    this.handleOnlineStatus(true);
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  public getOnlineStatus(): Observable<OnlinePayload> {
    return this.online$.asObservable();
  }

  public getNewId(): string {
    return this.afs.createId();
  }

  public docWithId$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(
      map((doc) => {
        let data = null;
        if (doc.payload.exists) {
          data = doc.payload.data();
          data.id = doc.payload.id;
        }

        return this.timestampDate.parseTimestampToDate(data);
      })
    );
  }

  public docsFromId$<T>(ref: CollectionPredicate<T>, ids: string[]): Observable<T[]> {
    if (ids.length === 0) {
      this.handleOnlineStatus(true);

      return new Observable(observer => {
        observer.next([]);
      });
    } else {
      const colRef = this.col(ref).ref.path;
      const refs = ids.map(id => `/${colRef}/${id}`);

      return this.docsFromRefs$(refs);
    }
  }

  public createLog(userId?: string): DocumentLog {
    if (!userId) {
      userId = this.localStore.getItemSync('userId');
    }

    const log: DocumentLog = {
      createdBy: userId,
      createdOn: this.getServerTime(),
      updatedBy: userId,
      updatedOn: this.getServerTime()
    };

    return log;
  }

  private updateLog(data: ObjectMap<any>, userId?: string): DocumentLog {
    if (!data.log) {
      throw Error('Log property must be in document to update');
    }

    if (!userId) {
      userId = this.localStore.getItemSync('userId');
    }

    data.log.updatedOn = this.getServerTime();
    data.log.updatedBy = userId;

    return data.log;
  }

  public docsFromRefs$<T>(refs: string[]): Observable<T[]> {
    const subjects = refs.map((ref) => {
      ref = this.doc(ref).ref.path;
      return this.docWithId$(ref);
    });

    return combineLatest([
      ...subjects
    ]) as Observable<T[]>;
  }

  private transformDoc<T>(snapshot: QueryDocumentSnapshot<T>, addDocRef?: boolean): any {
    const data: any = snapshot.data();

    if (data) {
      data.id = snapshot.id;
    }

    if (addDocRef) {
      data.__doc = data.payload.doc;
    }

    return this.timestampDate.parseTimestampToDate(data);
  }

  public getServerTime(): Date {
    return firebase.firestore.Timestamp.now().toDate(); // firebase.firestore.FieldValue.serverTimestamp();
  }

  public colWithIds$<T>(
    ref: CollectionPredicate<T>, queryfn?: QueryFn, addDocRef?: boolean
  ): Observable<CollectionQueryResultItem<T>[] | T[]> {
    return this.col(ref, queryfn).snapshotChanges().pipe(
      map(docs => {
        return docs.map((doc) => {
          const data = this.transformDoc(doc.payload.doc);
          if (addDocRef) {
            return {
              ref: doc.payload.doc,
              document: data
            };
          } else {
            return data;
          }
        });
      })
    );
  }

  public batchUpdate<T>(docs: DocItem[], colRef: CollectionPredicate<T>): Promise<any> {
    this.handleOnlineStatus(true);

    return Promise.all(chunk(docs, this.BATCH_SIZE).map(async (batchData) => {
      const batch = firebase.firestore().batch();

      batchData.forEach(data => {
        data.log = this.updateLog(data);

        const docRef = `${colRef}/${data.id}`;
        batch.update(firebase.firestore().doc(docRef), data);
      });

      return batch.commit();
    }));
  }

  public batchSet<T>(docs: DocItem[], colRef: CollectionPredicate<T>): Promise<any> {
    this.handleOnlineStatus(true);

    return Promise.all(chunk(docs, this.BATCH_SIZE).map(async (batchData) => {
      const batch = firebase.firestore().batch();

      batchData.forEach(data => {
        if (data.log) {
          data.log = this.updateLog(data);
        } else {
          data.log = this.createLog();
        }

        const docRef = `${colRef}/${data.id}`;
        batch.set(firebase.firestore().doc(docRef), data);
      });

      return batch.commit();
    }));
  }

  public add(colRef: CollectionPredicate<any>, data: ObjectMap<any>): Promise<any> {
    // data = this.indexService.updateSearchIndex(data);
    data.log = this.createLog();

    return this.col(colRef).add(data);
  }

  public remove(ref: DocPredicate<any>): Promise<void> {
    return this.doc(ref).delete();
  }

  public set(docRef: DocPredicate<any>, data: DocItem): Promise<any> {
    if (data.log) {
      data.log = this.updateLog(data);
    } else {
      data.log = this.createLog();
    }

    return this.doc(docRef).set(data);
  }

  public update(docRef: DocPredicate<any>, data: DocItem): Promise<any> {
    data.log = this.updateLog(data);

    return this.doc(docRef).update(data);
  }

  private colNew(ref: string): CollectionReference<DocumentData> {
    return collection(this.firestore, ref);
  }

  private docNew(ref: string): DocumentReference<DocumentData> {
    return document(this.firestore, ref);
  }

  public docWithIdNew$<T = any>(ref: string): Observable<T> {
    return docData(this.docNew(ref)).pipe(
      map((doc) => {
        return this.timestampDate.parseTimestampToDate(doc);
      }),
    );
  }

  public async colWithIdsNoCache<T = any>(ref: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}, addDocRef?: boolean): Promise<T[]> {
    const q: QueryNew<DocumentData> = query(this.colNew(ref), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

    const s = (await getDocsFromServer(q)).docs;
    const docs: T[] = [];
    s.forEach((doc) => {
      if (doc.exists()) {
        const data: any = doc.data();
        data.id = doc.id;
        if (addDocRef) (data as any).__doc = doc;
        docs.push(this.timestampDate.parseTimestampToDate(data));
      }
    });
    // const q = query(this.colNew(ref), ...this.wheres(queryFn()));
    // const querySnapshot = await getDocs(q);
    // const docs: T[] = [];
    // querySnapshot.forEach(doc => {
    //     if (doc.exists()) {
    //         docs.push(doc.data() as T);
    //     }
    // });
    return docs;
  }

  private wheres(args: Where[]): QueryConstraint[] {
    return args.map(arg => {
      return where(arg[0], arg[1], arg[2]);
    });
  }

  private queryConstraintOptions<T>(opts: QueryConstraintOptions<T>) {
    const filters: QueryConstraint[] = []
    if (opts.orderBy) {
      for (const ord of opts.orderBy) {
        filters.push(orderBy(ord.field, ord.val));
      }
    }

    if (opts.startAfter) {
      filters.push(startAfter(opts.startAfter));
    }

    if (opts.endBefore) {
      filters.push(endBefore(opts.endBefore));
    }
    if (opts.limit) {
      filters.push(limit(opts.limit));
    }
    if (opts.startAt) {
      filters.push(startAt(opts.startAt));
    }
    if (opts.limitToLast) {
      filters.push(limitToLast(opts.limitToLast))
    }
    return filters;
  }

  public async getCounts(ref: string, queryFn?: () => Where[]): Promise<number> {
    let colRef: QueryNew<DocumentData>;
    if (queryFn) {
      colRef = query(this.colNew(ref), ...this.wheres(queryFn()));
    } else {
      colRef = this.colNew(ref);
    }
    return (await getCountFromServer(colRef)).data().count;
  }

  public colWithIdsNew$<T = any>(ref: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}, addDocRef?: boolean): Observable<T[]> {
    const colRef: QueryNew<DocumentData> = query(this.colNew(ref), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

    return collectionSnapshots(colRef).pipe(map(data => {
      return data.map(doc => {
        let data = doc.data() as T;
        data = this.timestampDate.parseTimestampToDate(data);
        data = this.timestampDate.parseStringToDate(data);
        if (addDocRef) (data as any).__doc = doc;
        return data;
      })
    }))

    // let colRef: QueryNew<DocumentData>;
    // if (queryFn) {
    //     colRef = query(this.colNew(ref), ...this.wheres(queryFn()));
    // } else {
    //     colRef = this.colNew(ref);
    // }
    // return collectionSnapshots(colRef).pipe(map(data => {
    //     return data.map(doc => {
    //         let data = doc.data() as T;
    //         data = this.timestampDate.parseTimestampToDate(data);
    //         data = this.timestampDate.parseStringToDate(data);
    //         return data;
    //     })
    // }))
  }

  public colGroupWithIdsNew$<T>(collectionId: string, queryFn: () => Where[] = () => [], opts: QueryConstraintOptions<T> = {}, addDocRef?: boolean) {
    const colRef: QueryNew<DocumentData> = query(collectionGroup(this.firestore, collectionId), ...this.wheres(queryFn()).concat(this.queryConstraintOptions(opts)));

    return collectionSnapshots(colRef).pipe(map(data => {
      return data.map(doc => {
        let data = doc.data() as T;
        data = this.timestampDate.parseTimestampToDate(data);
        data = this.timestampDate.parseStringToDate(data);
        if (addDocRef) (data as any).__doc = doc;
        return data;
      })
    }))
  }

}
