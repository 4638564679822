import { DocumentLog, ObjectMap } from '../interface';

export enum ChatMsgVisibilityTypes {
    both = 1,
    receiver,
    none
}

export enum OnlineStatusTypes {
    ONLINE = 1,
    OFFLINE
}

export interface ChatRecipientInfo {
    userId: string;
    lastReadDate: Date;
    unreadCount: number;
    typingStatus: boolean;
}

export class ChatMsg {
    id?: string;
    ref?: {
        title: string;
        message: string;
    };
    message: string;
    senderId?: string;
    visibility: ChatMsgVisibilityTypes;
    log?: DocumentLog;
}

export class ChatObject {
    id: string;
    recipientIds: string[];
    recipientUIDs: string[];
    recipientsInfo: ObjectMap<ChatRecipientInfo>;
    log: DocumentLog;
    lastMessage: string;
    lastMessageDate: Date;
    hasUnread: boolean;
}
