import { Component, EventEmitter, Input, OnChanges, OnInit, Output, AfterViewInit, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { isEqual } from 'lodash';
import { ITableData, IDataTableConfig, RowOption } from '@models/interface';

@Component({
    selector: 'app-data-table',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges, AfterViewInit {
    @Input()
    public configOptions: IDataTableConfig;

    @Output()
    public rowActionSelected: EventEmitter<any> = new EventEmitter();

    @Output()
    public rowSelectionChange: EventEmitter<ITableData> = new EventEmitter();

    @Output()
    public rowSelected: EventEmitter<any> = new EventEmitter();

    @Output()
    public listSelected: EventEmitter<any> = new EventEmitter();

    @Output()
    public actionButtonClicked: EventEmitter<any> = new EventEmitter();

    public selection = new SelectionModel<any>(true, []);
    public matTableDataSource = new MatTableDataSource<any>();
    public displayProperties: string[] = [];
    public rowOptions: RowOption[] = [];

    public constructor() { }

    ngOnInit() { }

    ngAfterViewInit() {
        this.selection.changed.subscribe(payload => {
            payload.added.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    item._metadata.checked = true;
                    this.rowSelectionChange.emit(item);
                }
            });

            payload.removed.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    item._metadata.checked = false;
                    this.rowSelectionChange.emit(item);
                }
            });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.configOptions && this.isNewChange(changes.configOptions)) {
            this.displayProperties = this.configOptions.displayProperties.slice();
            this.matTableDataSource.data = this.configOptions.data;

            if (this.configOptions.emitSelectedList) {
                this.selection.clear();
            }

            // apply selection if specified
            this.matTableDataSource.data.forEach((item: ITableData) => {
                if (item && item._metadata) {
                    if (item._metadata.checked) {
                        this.selection.select(item);
                    } else {
                        this.selection.deselect(item);
                    }
                }
            });

            if (this.configOptions.rowOptions.length > 0) {
                this.rowOptions = this.configOptions.rowOptions;
                this.displayProperties.push('_actions_');
            }

            if (this.configOptions.showNextIcon) {
                this.displayProperties.push('_next_icon_');
            }

            if (this.configOptions.showSelectCheckboxes) {
                this.displayProperties.unshift('select');
            }
        }
    }

    private isNewChange(change: any) {
        const prev = change.previousValue;
        const current = change.currentValue;

        return !isEqual(prev, current);
    }

    /* A nice lodash based method for detecting changes between two objects
    changes(object, base) {
        return transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? this.changes(value, base[key]) : value;
            }
        });
    }*/

    /** Whether all filtered rows are selected. */
    isAllFilteredRowsSelected() {
        return this.matTableDataSource.filteredData.every(data => this.selection.isSelected(data));
    }

    /** Whether the selection it totally matches the filtered rows. */
    isMasterToggleChecked() {
        return this.selection.hasValue() &&
            this.isAllFilteredRowsSelected() &&
            this.selection.selected.length >= this.matTableDataSource.filteredData.length;
    }

    /**
     * Whether there is a selection that doesn't capture all the
     * filtered rows there are no filtered rows displayed.
     */
    isMasterToggleIndeterminate() {
        return this.selection.hasValue() &&
            (!this.isAllFilteredRowsSelected() || !this.matTableDataSource.filteredData.length);
    }

    /** Selects all filtered rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isMasterToggleChecked()) {
            this.selection.clear();
        } else {
            this.matTableDataSource.filteredData.forEach(data => {
                if (data._metadata && !data._metadata.disabled) {
                    this.selection.select(data);
                }
            });
        }

        this.actionButtonClick();
        this.listSelected.emit(this.selection.selected);
    }

    handleRowSelect(row) {
        if (this.configOptions.allowSelection) {
            this.selection.toggle(row);
        }

        if (this.rowSelected) {
            this.rowSelected.emit(row);
        }
    }

    handleRowCheckbox(row) {
        this.selection.toggle(row);

        if (this.configOptions.emitSelectedList) {
            this.listSelected.emit(this.selection.selected);
        }
    }

    selectRowAction(option, row) {
        this.rowActionSelected.emit({ option, data: row });
    }

    actionButtonClick() {
        this.actionButtonClicked.emit(this.selection.selected);
    }
}
