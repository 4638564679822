<div fxLayout="column" fxLayoutAlign="start top" class="modal">
    <div *ngIf="!hideHeader" class="modal_header" [ngClass]="[headerBgColor || '']" fxLayout="row"
        fxLayoutAlign="start center">
        <div *ngIf="showBackButton()">
            <button mat-icon-button (click)="closeModal(data?.response)">
                <mat-icon svgIcon="arrow_back"></mat-icon>
            </button>
        </div>

        <ng-content select=".modal_header"></ng-content>

        <div class="right_control" *ngIf="!hideCloseButton">
            <button mat-icon-button (click)="closeAllModals()">
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="modal_body" fxFlex="stretch">
        <ng-content select=".modal_body"></ng-content>
    </div>

    <div class="modal_footer">
        <ng-content select=".modal_footer"></ng-content>
    </div>
</div>