import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { Sub } from '@core/subscriptions';
import { LabelService } from '@core/services/label/label.service';
import { UtilitiesService } from '@core/services/utilities/utilities.service';

export interface PromptInputConfig {
    title?: string;
    message: string;
    type?: 'input' | 'textarea' | 'number';
    required?: boolean;
    initialValue?: string;
    matchTextInput?: string;
    actionBtn?: string;
    headerBg?: 'bg-accent' | 'bg-primary' | 'bg-warn';
}

@Component({
    selector: 'app-prompt-dialogue',
    styleUrls: ['./prompt-dialogue.component.css'],
    templateUrl: './prompt-dialogue.component.html',
})
export class PromptDialogueComponent implements OnInit, OnDestroy {
    public promptForm: FormGroup;
    public labels: ILabelsProvider = this.labelService.defaultProvider();
    public config: PromptInputConfig;

    private sub: Sub = new Sub();

    constructor(
        public dialogRef: MatDialogRef<PromptDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PromptInputConfig,
        private fb: FormBuilder,
        private labelService: LabelService,
        private utilitiesService: UtilitiesService
    ) { }

    ngOnInit() {
        this.config = Object.assign({}, {
            type: 'input'
        }, this.data);

        if (this.config.required) {
            this.dialogRef.disableClose = true;
        }

        this.createForm();

        this.sub.add(
            this.labelService.getLabels('app-prompt-dialogue').subscribe(lbs => {
                this.labels = lbs;
            }, e => this.utilitiesService.errHandler(e))
        );
    }

    public disableBtn(): boolean {
        if (this.config.matchTextInput) {
            return this.promptForm.value.inputValue !== this.config.matchTextInput;
        }

        return this.config.required && !this.promptForm.value.inputValue;
    }

    continue() {
        this.dialogRef.close(this.promptForm.value.inputValue);
    }

    cancel(): void {
        this.dialogRef.close();
    }

    createForm() {
        this.promptForm = this.fb.group({
            inputValue: [this.config.initialValue || '']
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
