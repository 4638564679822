import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteGuardsModule } from './guards/guard.module';
import { AppServiceModule } from './services/service.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AppServiceModule,
    RouteGuardsModule
  ],
  exports: [
    AppServiceModule,
    RouteGuardsModule
  ]
})
export class CoreModule { }
