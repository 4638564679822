<app-modal [hideCloseButton]="true">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{config?.title}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <p>{{config?.message}}</p>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-end">
            <button mat-stroked-button (click)="cancel()">{{config?.cancelBtn}}</button>
            <button color="primary" mat-raised-button (click)="continue()">{{config?.confirmBtn}}</button>
        </div>
    </div>
</app-modal>