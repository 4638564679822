import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ChatService } from '@core/services/chat/chat.service';
import { LabelService } from '@core/services/label/label.service';
import { UserService } from '@core/services/user/user.service';
import { Sub } from '@core/subscriptions';
import { ChatMsg, ChatMsgVisibilityTypes, ChatObject, DocItem, NoticeBoardItem, ObjectMap, User, UserReference } from '@models/commons';
import { Event } from 'functions/src/data-models/commons/model/event';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-reaction-dialog',
  templateUrl: './reaction-dialog.component.html',
  styleUrls: ['./reaction-dialog.component.scss']
})
export class ReactionDialogComponent implements OnInit, OnDestroy {

  protected labels: ILabelsProvider = this.labelService.defaultProvider();
  protected isLoading = false;
  public form: FormGroup;
  private item: NoticeBoardItem;
  private currentUserId: string;
  private sub = new Sub();
  public chatObject: ChatObject = undefined;
  public recipientUserId: string;
  private myReference: UserReference;
  public usersMap: ObjectMap<User> = {};
  private event: Event;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { item: NoticeBoardItem; event?: Event },
    private dialogRef: MatDialogRef<ReactionDialogComponent>,
    private labelService: LabelService,
    private fb: FormBuilder,
    private chatService: ChatService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.item = cloneDeep(this.data.item);
    this.event = cloneDeep(this.data.event);
    this.sub.add(
      this.labelService.getLabels('app-reaction-dialog').subscribe(lbs => {
        this.labels = lbs;
      })
    );
    this.setupForm();

    this.sub.add(
      this.chatService.getChatObjectById(this.chatService.getChatId(this.item.userId)).subscribe(chat => {
        if (!chat) {
          return this.chatService.createChatObjectForUsers(this.item.userId);
        }

        this.chatObject = chat;

        if (!this.currentUserId) {
          this.currentUserId = this.userService.getCurrentUserId();
          this.recipientUserId = this.chatObject.recipientIds.filter(id => id !== this.currentUserId)[0];

          this.getUsers();
          // this.fetchPastChats();
          // this.fetchUpcomingChats();
        }

        const me = this.chatObject.recipientsInfo[this.currentUserId];
        let updateToSave: ObjectMap<any>;

        if (me.unreadCount !== 0) {
          updateToSave = updateToSave || {};
          updateToSave[`recipientsInfo.${this.currentUserId}.unreadCount`] = 0;
        }

        if (updateToSave) {
          updateToSave.hasUnread = this.chatObject.recipientsInfo[this.recipientUserId].unreadCount > 0;

          updateToSave[`recipientsInfo.${this.currentUserId}.lastReadDate`] = new Date();
          this.updateChatMeta(updateToSave);
        }

        // this.utilitiesService.delay(800).subscribe(() => {
        //   this.textInputElem.nativeElement.focus();
        // });
      })
    )
  }

  private setupForm(): void {
    this.form = this.fb.group({
      message: ['', Validators.required],
    });
  }

  private getUsers(): void {
    this.sub.add(
      this.userService.getUsersFromIds(this.chatObject.recipientIds).subscribe(users => {
        users.forEach(user => this.usersMap[user.id] = user);
      }),
      this.userService.getReferenceById(this.currentUserId, this.recipientUserId).subscribe(r => {
        this.myReference = r;
      }),
      this.userService.getReferenceById(this.recipientUserId, this.currentUserId).subscribe(r => {
        if (r.messageCount !== 0) {
          r.messageCount = 0;

          this.userService.updateReferenceById(this.recipientUserId, r as DocItem, this.currentUserId);
        }
      })
    );
  }

  private updateChatMeta(update: ObjectMap<any>): Promise<void> {
    update.id = this.chatObject.id;
    update.log = this.chatObject.log;

    return this.chatService.updateChatObject(update as DocItem);
  }

  public react() {
    const model = this.form.value;
    if (model.message) {
      const chat: ChatMsg = {
        ref: {
          title: this.event?.title || '',
          message: this.item.message
        },
        message: model.message,
        senderId: this.currentUserId,
        visibility: ChatMsgVisibilityTypes.both
      };

      this.chatService.sendMsg(chat, this.chatObject.id).then(async () => {
        // update meta data counts
        const recipient = this.chatObject.recipientsInfo[this.recipientUserId];
        recipient.unreadCount = recipient.unreadCount > 0 ? recipient.unreadCount + 1 : 1;

        const update: ObjectMap<any> = {
          lastMessage: chat.message.substring(0, 256),
          lastMessageDate: chat.log.createdOn,
          hasUnread: true,
        };

        update[`recipientsInfo.${this.recipientUserId}.unreadCount`] = recipient.unreadCount;
        await this.updateChatMeta(update);

        this.myReference.messageCount += 1;
        await this.userService.updateReferenceById(this.currentUserId, this.myReference as DocItem, this.recipientUserId);
        this.openChat();
        this.dialogRef.close();
      })
    }
  }

  private async openChat() {
    this.router.navigateByUrl(`/dashboard/messages/detail/${this.chatObject.id}`);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
