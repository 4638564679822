import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { UserData, UserServiceMap, ISearchUser } from '@models/commons';
import { AuthService } from '../auth/auth.service';
import { take, map, mergeAll, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class CloudFunctionService {
  constructor(
    private authService: AuthService,
    private afFn: AngularFireFunctions
  ) { }

  private callable$(functionName: string) {
    return this.afFn.httpsCallable(functionName);
  }

  private callablePromiseHandler<T, R>(functionName: string, data: T): Promise<R> {
    return this.callable$(functionName)(data).pipe(take(1)).toPromise();
  }

  public verifyNewUserCredentials(token: string, userId: string): Promise<{ email: string; isMfaEnabled: boolean; phoneNumber: string; }> {
    return new Promise((resolve) => {
      this.callablePromiseHandler<any, any>('verifyNewUser', { token, userId }).then(res => {
        resolve(res);
      }).catch(() => {
        resolve(null);
      });
    });
  }

  public getUserDataByUID(): Promise<UserData> {
    return this.authService.getAuthUser().pipe(
      filter(a => a !== null),
      take(1),
      map(auth => {
        if (auth) {
          return this.callable$('getUserDataByUID')({ uid: auth.uid });
        } else {
          return of(null);
        }
      }),
      mergeAll()
    ).toPromise();
  }

  public getUserDataByEmail(email: string): Promise<UserData> {
    return this.callablePromiseHandler('getUserDataByEmail', { email })
  }

  public updateUIDInUserData(token: string, UID: string, phone: string, userId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.callablePromiseHandler('updateUIDInUserData', { token, UID, phoneNumber: phone, userId }).then(_res => {
        resolve();
      }).catch(() => {
        reject(null);
      });
    });
  }

  public activateProfile(
    userId: string, serviceId: string, companyId: string
  ): Promise<firebase.functions.HttpsCallableResult> {
    return this.callablePromiseHandler('activateProfile', { userId, serviceId, companyId });
  }

  // @Check
  public updateReferences(payload: UserServiceMap, recalculateDistance?: boolean, uRefs?: boolean): Promise<firebase.functions.HttpsCallableResult> {
    return this.callablePromiseHandler('updateUserReferences', { payload, recalculateDistance, updateRefs: uRefs });
  }

  public async removeNoticeBoardRefsForUsers(userIds: [string, string], companyId: string, serviceId: string): Promise<void> {
    return this.callablePromiseHandler('removeNoticeBoardRefsForUsers', { userIds, companyId, serviceId }).then(() => Promise.resolve());
  }

  public async updateNoticeBoardRefsForUsers(userIds: [string, string], companyId: string, serviceId: string): Promise<void> {
    return this.callablePromiseHandler('updateNoticeBoardRefsForUsers', { userIds, companyId, serviceId }).then(() => Promise.resolve());
  }

  public async noticeBoardItemCreated(noticeBoardId: string, serviceMap: UserServiceMap): Promise<void> {
    return this.callablePromiseHandler('noticeBoardItemCreated', { noticeBoardId, serviceMap }).then(() => Promise.resolve());
  }

  public async noticeBoardItemDeleted(noticeBoardId: string, serviceMap: UserServiceMap): Promise<void> {
    return this.callablePromiseHandler('noticeBoardItemDeleted', { noticeBoardId, serviceMap }).then(() => Promise.resolve());
  }

  public searchUsers(payload: ISearchUser): Promise<firebase.functions.HttpsCallableResult> {
    payload.search = payload.search.toLocaleLowerCase();
    return this.callablePromiseHandler('searchUsers', payload);
  }

  public async removeProfile(payload: UserServiceMap): Promise<UserServiceMap> {
    return this.callablePromiseHandler<UserServiceMap, UserServiceMap>('removeProfile', payload).then(d => Promise.resolve(d));
  }

  public async deleteAccount(payload: UserServiceMap): Promise<UserServiceMap> {
    return this.callablePromiseHandler<UserServiceMap, UserServiceMap>('deleteAccount', payload).then(d => Promise.resolve(d));
  }
}
