import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NoticeBoardItem, ObjectMap, User } from '@models/commons';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { UtilitiesService } from '@core/services/utilities/utilities.service';
import { LabelService } from '@core/services/label/label.service';
import { Sub } from '@core/subscriptions';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { take } from 'rxjs/operators';
import { EventService } from '@core/services/event/event.service';
import { uniq } from 'lodash';
import { Event } from 'functions/src/data-models/commons/model/event';
import { ReactionDialogComponent } from '@shared/reaction-dialog/reaction-dialog.component';
import { DialogService } from '@core/services/dialog/dialog.service';

@Component({
  selector: 'app-notice-board-items',
  templateUrl: './notice-board-items.component.html',
  styleUrls: ['./notice-board-items.component.scss']
})
export class NoticeBoardItemsComponent implements OnInit, OnDestroy {
  @Input() public items: NoticeBoardItem[];
  @Input() public usersMap: ObjectMap<{ user: User }>;
  @Input() public pageView: 'all' | 'my';

  @Output() onDelete = new EventEmitter<NoticeBoardItem>();
  @Output() onEdit = new EventEmitter<NoticeBoardItem>();
  @Output() onRepublish = new EventEmitter<NoticeBoardItem>();

  public expandedMap: ObjectMap<boolean> = {}; // contains expanded text item
  public summarySize = 100;
  public labels: ILabelsProvider = this.labelService.defaultProvider();
  public timeAgoMap: ObjectMap<string> = {};
  protected eventMap: ObjectMap<Event> = {};
  private currentUserId: string;
  private sub = new Sub();

  constructor(
    private localStore: LocalStorageService,
    private router: Router,
    private utilitiesService: UtilitiesService,
    private labelService: LabelService,
    private eventService: EventService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.currentUserId = this.localStore.getItemSync('userId');

    this.sub.add(
      this.labelService.getLabels('app-notice-board-items').subscribe(lbs => {
        this.labels = lbs;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.utilitiesService.isNewChange(changes.items) && this.items) {
      this.items.forEach(item => {
        this.timeAgoMap[item.id] = this.getTimeAgo(item.log.createdOn);
        this.getEvents();
      });
    }
  }

  public republish(item: NoticeBoardItem): void {
    this.onRepublish.emit(item);
  }

  public editNoticeBoard(item: NoticeBoardItem): void {
    this.onEdit.emit(item);
  }

  public deleteItem(item: NoticeBoardItem): void {
    this.onDelete.emit(item);
  }

  public gotoPublicProfile(item: NoticeBoardItem): void {
    if (item.userId !== this.currentUserId) {
      this.router.navigateByUrl('/dashboard/people/public/' + item.userId);
    }
  }

  private getTimeAgo(date: Date): string {
    return this.utilitiesService.timeAgo(date);
  }

  public getItemText(item: NoticeBoardItem): string {
    const res = this.expandedMap[item.id] ? item.message : item.message.substr(0, this.summarySize);
    return item.message.length > res.length ? res + '...' : res;
  }

  private getEvents() {
    const noticeBoardIds = uniq(this.items.map(item => item?.eventId));
    if (noticeBoardIds.length) {
      this.eventService.getEventsByIds(noticeBoardIds).pipe(take(1)).toPromise()
        .then(events => {
          events.forEach(event => {
            this.eventMap[event.id] = event;
          })
        });
    }
  }

  protected goToEvent(eventId: string) {
    this.router.navigateByUrl(`/dashboard/events/${eventId}`);
  }

  public openReactionDialog(item: NoticeBoardItem) {
    this.dialogService.showDialogue(ReactionDialogComponent, { data: { item, event: this.eventMap[item.eventId] }, width: '400px' })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}