import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { ObjectMap } from '@models/commons';
import { LabelService } from '@core/services/label/label.service';

@Component({
    selector: 'app-alert-dialogue',
    styleUrls: ['./alert-dialogue.component.css'],
    templateUrl: './alert-dialogue.component.html',
})
export class AlertDialogueComponent implements OnInit {
    public labels: ILabelsProvider = this.labelService.defaultProvider();

    constructor(
        public dialogRef: MatDialogRef<AlertDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ObjectMap<any>,
        private labelService: LabelService
    ) { }

    ngOnInit() {
        this.labelService.getLabels('app-alert-dialogue').subscribe(lbs => {
            this.labels = lbs;
        });
    }

    continue(): void {
        this.dialogRef.close(true);
    }
}
