<app-modal>
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{data.title}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <span [innerHtml]="data.message"></span>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayoutAlign="flex-end" fxLayout="row">
            <button mat-raised-button color="primary" (click)="continue()">{{labels.ok}}</button>
        </div>
    </div>
</app-modal>