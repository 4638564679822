import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { LabelService } from '@core/services/label/label.service';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserData, UserServiceMap, User, Service, Company } from '@models/commons';
import { Sub } from '@core/subscriptions';
import { Router } from '@angular/router';
import { CloudFunctionService } from '@core/services/fn/fn.service';
import { uniq, uniqBy } from 'lodash';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { IDataTableConfig, ITableData } from '@models/interface';
import { UserService } from '@core/services/user/user.service';
import { take, map, mergeAll } from 'rxjs/operators';
import { of, combineLatest, from } from 'rxjs';
import { CompanyService } from '@core/services/company/company.service';
import { ServiceService } from '@core/services/service/service.service';

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.scss']
})
export class SelectProfileComponent implements OnInit, OnDestroy {
  public labels: ILabelsProvider = this.labelService.defaultProvider();
  public isLoading = true;
  public configOptions: IDataTableConfig;

  private sub = new Sub();
  private profileMapList: { user: User; service: Service; company: Company; }[];

  constructor(
    public dialogRef: MatDialogRef<SelectProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { profiles?: UserServiceMap[]; },
    private labelService: LabelService,
    private router: Router,
    private fnService: CloudFunctionService,
    private localStore: LocalStorageService,
    private userService: UserService,
    private companyService: CompanyService,
    private serviceService: ServiceService
  ) { }

  ngOnInit() {
    this.sub.add(
      this.labelService.getLabels('app-select-profile').subscribe(lbs => {
        this.labels = lbs;
      })
    );

    const obs$ = this.data.profiles
      ? from(Promise.resolve(this.data.profiles))
      : from(this.fnService.getUserDataByUID()).pipe(
        map(userData => this.userService.getUserServiceMaps(userData))
      );

    obs$.pipe(take(1)).toPromise().then(profiles => {
      if (profiles.length === 1) {
        this.proceed(profiles[0]);
      } else {
        const companyIds = profiles.map(p => p.companyId);

        combineLatest([
          this.userService.getUsersFromMap(uniqBy(profiles, 'userId')),
          this.companyService.getCompaniesByIds(uniq(companyIds)),
          this.serviceService.getServicesFromServiceMaps(uniqBy(profiles, 'serviceId'))
        ]).pipe(take(1)).subscribe(arr => {
          const users = arr[0];
          const companies = arr[1];
          const services = arr[2];

          this.profileMapList = users.map(user => {
            return {
              user: user,
              service: services.find(s => s.id === user.serviceId),
              company: companies.find(c => c.id === user.companyId)
            };
          });

          this.updateTableConfig();
        });
      }
    });
  }

  public handleRowSelect(evt: ITableData): void {
    const user: User = evt._metadata.originalData.user as User;

    this.proceed({
      userId: user.id,
      companyId: user.companyId,
      serviceId: user.serviceId
    });
  }

  private updateTableConfig(): void {
    this.isLoading = false;

    this.configOptions = {
      data: this.profileMapList.map(m => {
        const payload: ITableData = {
          photoUrl: m.user.photoUrl,
          name: {
            title: `${m.user.firstName} ${m.user.lastName}`,
            subtitle: `${m.company.name} - ${m.service.name}`
          },
          _metadata: {
            originalData: m
          }
        };

        return payload;
      }),
      propertyWithImage: 'name',
      showSelectCheckboxes: false,
      rowOptions: [],
      displayHeaders: false,
      allowSelection: false,
      displayProperties: ['name'],
      headers: {}
    };
  }

  private proceed(user: UserServiceMap): void {
    const activeUserId = this.localStore.getItemSync('userId');

    if (activeUserId !== user.userId) {
      this.localStore.setAuthStorageItems(user);
      this.router.navigateByUrl('/dashboard');
    }

    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
