<app-modal [isLoading]="isLoading">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.reaction_message}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">

        <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px">
            <mat-form-field fxFlex="stretch">
                <textarea matInput minRows="4" matTextareaAutosize formControlName="message"
                    [placeholder]="labels.react_noticeboard" [maxlength]="256"></textarea>
            </mat-form-field>
        </form>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayoutAlign="flex-end" fxLayout="row">
            <button [disabled]="form.invalid || form.pristine" mat-raised-button color="primary"
                (click)="react()">{{labels.save}}</button>
        </div>
    </div>
</app-modal>