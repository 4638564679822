import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';

type ProgressHandler = (val: number) => void;

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private afStorage: AngularFireStorage
  ) { }

  public handleUploadWithTask(task: AngularFireUploadTask, progressHandler: ProgressHandler): Promise<any> {
    return new Promise((resolve, reject) => {
      task.percentageChanges().subscribe(value => {
        progressHandler(value);
      });

      task.then(snapshot => {
        return resolve(snapshot.ref.getDownloadURL());
      }, error => {
        console.log(error);
        return reject();
      });
    });
  }

  public base64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public uploadFile(file: File, path: string): Promise<string> {
    const task = this.afStorage.upload(path, file);

    return this.handleUploadWithTask(task, () => { });
  }

  public deleteFileByUrl(url: string): Promise<void> {
    return new Promise(async (resolve) => {
      // this try-catch prevents throwing error if file is not in storage to be deleted
      try {
        if (url) {
          await this.afStorage.storage.refFromURL(url).delete();
        }

        resolve();
      } catch (e) {
        resolve();
      }
    });
  }

  public deleteFilesByUrl(urls: string[]): Promise<any> {
    const promises = [];

    urls.forEach(url => {
      promises.push(this.deleteFileByUrl(url));
    });

    return Promise.all(promises);
  }

  public deleteFileByPath(path: string): Promise<void> {
    return new Promise(async (resolve) => {
      const storageBaseRoute = `https://storage.googleapis.com/${environment.firebase.storageBucket}`;

      // remove base path from full file path for relative reference
      const filePath = path.substr(storageBaseRoute.length + 1);

      // this try-catch prevents throwing error if file is not in storage to be deleted
      try {
        await this.afStorage.storage.ref(filePath).delete();
        resolve();
      } catch (e) {
        resolve();
      }
    });
  }

  public deleteFilesByPath(paths: string[]): Promise<any> {
    const promises = [];

    paths.forEach(path => {
      promises.push(this.deleteFileByPath(path));
    });

    return Promise.all(promises);
  }

  public getFilesFromInput(input: any): Promise<File[]> {
    return new Promise((resolve) => {
      /* wire up file reader */
      const target: DataTransfer = input.target;

      if (target.files.length > 0) {
        resolve(Array.from(target.files));
      }
    });
  }

  public uploadBase64Image(data: string, path: string, progressHandler: ProgressHandler): Promise<string> {
    const task: AngularFireUploadTask = this.afStorage.ref(path).putString(data, 'data_url');

    return this.handleUploadWithTask(task, progressHandler);
  }

  public uploadFileImage(file: File, path: string, progressHandler: ProgressHandler): Promise<string> {
    const task: AngularFireUploadTask = this.afStorage.upload(path, file);

    return this.handleUploadWithTask(task, progressHandler);
  }

  public getFileFromUrl(url: string): Promise<File> {
    return new Promise((resolve, reject) => {
      if (!url.startsWith('http') && !url.startsWith('//')) {
        url = `${location.origin}${url.startsWith('/') ? '' : '/'}${url}`;
      } else {
        const proxy = 'https://europe-west1-volunteer4work-dev.cloudfunctions.net/getImageFromUrl/fetch/image';
        url = `${proxy}?resource=${encodeURIComponent(url)}`;
      }

      fetch(url).then(r => r.blob()).then(b => {
        if (b.type.startsWith('image')) {
          resolve(b as File);
        } else {
          reject('Resource is not an image');
        }
      }).catch(e => {
        reject('Error: Invalid image url. Response payload: ' + e?.toString());
      });
    });
  }
}
