import { UserStatusTypes, UserHiddenTypes } from '../enum';
import { DocumentLog, GeoPoint, ObjectMap, ISearchIndex } from '../interface';

export type GenderTypes = 'male' | 'female' | '';

export const countryDialingCodes = [
  '+31',
  '+234'
];

export interface LookingForMap {
  male: boolean;
  female: boolean;
}

export interface UserPersonalText {
  youAre: string;
  youLoveTo: string;
  typicallyYou: string;
  socialCall: string;
}

export interface ReferencedUser {
  birthday: string;
  distance: number;
  gender: GenderTypes;
  lookingFor: LookingForMap;
  published: boolean;
  location: GeoPoint;
}

// `companies/{companyId}/services/{serviceId}/users/{userId}/references`
export interface UserReference {
  id: string; // same as referenced user id
  hidden: UserHiddenTypes;
  messageCount: number;
  log: DocumentLog;
  referencedUser: ReferencedUser;
  seen: boolean;
  starred: boolean;
}

// To get the user document reference, we use
// `companies/{companyId}/services/{serviceId}/users/{userId}`;
export interface UserServiceMap {
  companyId: string;
  serviceId: string;
  userId: string;
}

export class UserData {
  id: string;
  uid?: string;
  email: string;
  companies: ObjectMap<ObjectMap<UserServiceMap>>; // company id map to service id which map to service info
  authToken?: string;
  language?: string; // defaults to nl
  log: DocumentLog;
  termsAccepted: boolean;
  authPhoneNumber: string;

  constructor(config: UserServiceMap, email: string, lang?: string) {
    this.email = email;
    this.language = lang || 'nl';
    this.termsAccepted = true;
    this.authPhoneNumber = '';

    this.companies = {};
    this.companies[config.companyId] = {};
    this.companies[config.companyId][config.serviceId] = {
      companyId: config.companyId,
      serviceId: config.serviceId,
      userId: config.userId
    };
  }
}

export interface ISearchUser {
  userInfo: UserServiceMap;
  search: string;
  gender: GenderTypes;
  lookingFor: LookingForMap;
}

export interface ISocialInterest {
  company: boolean;
  dating: boolean;
}

export interface UserSettings {
  pushNotifications: boolean;
  emailNotifications: boolean;
}

export class User {
  id?: string;
  firstName: string;
  lastName: string;
  gender: GenderTypes;
  dateOfBirth: string; // format is: yyyy-MM-dd
  postalcode: string;
  housenumber: number;
  email: string;
  lastActive: Date;
  photoUrl: string;
  profileBgImg: string;
  lookingFor: LookingForMap;
  log: DocumentLog;
  personalText: UserPersonalText;
  location?: GeoPoint;
  published: boolean;
  phoneNumber?: string;
  status: UserStatusTypes;
  interests: string[];
  companyId: string;
  serviceId: string;
  searchIndex: ISearchIndex;
  socialInterest: ISocialInterest;
  settings: UserSettings;
  UID?: string;

  constructor() {
    this.interests = [];
    this.gender = '';
    this.settings = {
      pushNotifications: false,
      emailNotifications: true,
    }

    this.personalText = {
      youLoveTo: '',
      youAre: '',
      typicallyYou: '',
      socialCall: ''
    };
  }
}

export interface UserCardPayload<T> {
  user: User;
  reference: UserReference;
  docRef?: T;
}
