import { Injectable } from '@angular/core';
import { ObjectMap } from '@models/commons';

export interface StateStore {
  id: string;
  state: ObjectMap<any>;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private stateStore: ObjectMap<StateStore> = {};

  constructor() { }

  public setState(id: string, state: ObjectMap<any> = {}): void {
    this.stateStore[id] = {
      id, state
    };
  }

  public getState<T>(id: string, prop?: string): T {
    if (this.stateStore[id]) {
      if (prop) {
        return this.stateStore[id].state[prop];
      } else {
        return this.stateStore[id].state as T;
      }
    } else {
      return null;
    }
  }

  public patchState(id: string, patch?: ObjectMap<any>): void {
    this.stateStore[id] = this.stateStore[id] || { id, state: {} };
    this.stateStore[id].state = this.stateStore[id].state || {};

    this.stateStore[id].state = Object.assign({}, this.stateStore[id].state, patch);
  }
}
