import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Service, UserServiceMap } from '@models/commons';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  constructor(
    private afDb: FirestoreService
  ) { }

  public getServiceById(serviceId: string, companyId: string): Observable<Service> {
    return this.afDb.docWithId$(`companies/${companyId}/services/${serviceId}`);
  }

  public getServicesFromServiceMaps(maps: UserServiceMap[]): Observable<Service[]> {
    return combineLatest(maps.map(m => this.getServiceById(m.serviceId, m.companyId)));
  }
}
