<app-modal [hideCloseButton]="true" [headerBgColor]="config.headerBg">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{data.title}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 15px;">
        <p>{{data.message}}</p>
        <div style="margin-bottom: 15px;" *ngIf="config.matchTextInput">{{labels.match_text_input_below}} 
            <div class="match_text_input">{{config.matchTextInput}}</div>
        </div>

        <form [formGroup]="promptForm">
            <mat-form-field appearance="outline" appearance="outline" class="w-100">
                <input *ngIf="config.type === 'input'" type="text" matInput [placeholder]="labels.input"
                    class="form-control" formControlName="inputValue">

                <input *ngIf="config.type === 'number'" type="number" matInput [placeholder]="labels.input"
                    class="form-control" formControlName="inputValue">

                <textarea *ngIf="config.type === 'textarea'" matInput formControlName="inputValue" matTextareaAutosize
                    minRows="3" [placeholder]="labels.input"></textarea>
            </mat-form-field>
        </form>
    </div>

    <div class="modal_footer" #modal_footer>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-end">
            <button mat-stroked-button (click)="cancel()">{{labels.cancel}}</button>
            <button (click)="continue()" [disabled]="disableBtn()" color="primary"
                mat-raised-button>{{config.actionBtn || labels.submit}}</button>
        </div>
    </div>
</app-modal>