import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { keys } from 'lodash';
import { ObjectMap, User, UserServiceMap } from '@models/commons';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private subjects: ObjectMap<BehaviorSubject<string>> = {};

    constructor() { }

    private next(name: string, value: string) {
        if (this.subjects[name]) {
            const subject: BehaviorSubject<string> = this.subjects[name];

            subject.next(value);
        }
    }

    public setItem(name: string, value: string) {
        localStorage.setItem(name, value);
        this.next(name, value);
    }

    public removeItem(name: string) {
        localStorage.removeItem(name);
        this.next(name, undefined);
    }

    public removeItems(names: string[]) {
        names.forEach(name => {
            localStorage.removeItem(name);
            this.next(name, undefined);
        });
    }

    public getItem(name: string): BehaviorSubject<string> {
        if (this.subjects[name]) {
            return this.subjects[name];
        } else {
            this.subjects[name] = new BehaviorSubject(localStorage.getItem(name));

            return this.subjects[name];
        }
    }

    public getItemSync(name: string): string {
        return localStorage.getItem(name);
    }

    public getItems(props: string[]): Observable<string[]> {
        const subjects = props.map(key => this.getItem(key));
        return combineLatest([
            ...subjects
        ]);
    }

    public clear() {
        localStorage.clear();

        keys(this.subjects).forEach((key) => {
            this.removeItem(key);
        });
    }

    public setAuthStorageItems(map: UserServiceMap): void {
        this.setItem('userId', map.userId);
        this.setItem('serviceId', map.serviceId);
        this.setItem('companyId', map.companyId);
    }

    public getServiceMap(): UserServiceMap {
        return {
            companyId: this.getItemSync('companyId'),
            serviceId: this.getItemSync('serviceId'),
            userId: this.getItemSync('userId')
        };
    }
}
