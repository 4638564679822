<app-modal [hideCloseButton]="true" [hideBackButton]="true" [isLoading]="isLoading">
    <div class="modal_header" #modal_header>
        <span class="modal_title">
            {{labels.select_profile}}
        </span>
    </div>

    <div class="modal_body" #modal_body style="overflow: hidden; padding: 10px;">
        <div *ngIf="configOptions">
            <app-data-table (rowSelected)="handleRowSelect($event)" [configOptions]="configOptions"></app-data-table>
        </div>
    </div>
</app-modal>