import { Injectable, SimpleChange } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { words, upperFirst, each, endsWith } from 'lodash';
import { ObjectMap } from '@models/commons';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {
    constructor() { }

    public isValidPassword(password: string): boolean {
        // full regex for at least 1 digit, lowercase, uppercase and non-word
        // /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password);

        return password.length >= 6 && /(?=.*\d)(?=.*[A-Z])/.test(password);
    }

    public generatePinCode(size: number = 4): string {
        const result: string[] = [];
        const possibleCharacters = '0123456789';
        const charSize = possibleCharacters.length;

        for (let i = 0; i < size; i++) {
            result.push(
                possibleCharacters.charAt(Math.floor(Math.random() * charSize))
            );
        }

        return result.join('');
    }

    public getQueryParam(name: string): string {
        const url = window.location.href;

        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }

        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    public getKeysForEnum(enumObj: ObjectMap<any>): number[] {
        const results: number[] = [];
        Object.keys(enumObj).map((type) => {
            if (!isNaN(parseInt(type, 10))) {
                results.push(parseInt(type, 10));
            }
        });

        return results;
    }

    public delay<T>(duration: number, val?: T): Observable<T> {
        return of(val).pipe(delay(duration), take(1));
    }

    public isNewChange(change: SimpleChange) {
        return !isEqual(change && change.previousValue, change && change.currentValue);
    }

    public errHandler(err: any): void {
        let e: string;
        if (typeof err === 'string') {
            e = err;
        } else {
            e = JSON.stringify({ Error: err });
        }

        console.log(e);
        throw new Error(e);
    }

    public getLocaleDateString(date: Date, options?: ObjectMap<any>): string {
        const defaultOptions = {
            day: 'numeric',
            month: 'short',
            weekday: 'short'
        };
        return new Date(date).toLocaleDateString(undefined, Object.assign({}, defaultOptions, options || {}) as any);
    }

    public getLocaleTimeString(date: Date, options?: ObjectMap<any>): string {
        const defaultOptions = {
            hour: 'numeric',
            minute: 'numeric'
        };

        const opts: any = Object.assign({}, defaultOptions, options || {});

        return date.toLocaleTimeString(undefined, opts);
    }

    public getLocaleDateWithYear(date: Date): string {
        return this.getLocaleDateString(date, { year: 'numeric' });
    }

    public timeAgo(date: Date) {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const msPerMonth = msPerDay * 30;
        const msPerYear = msPerDay * 365;

        date = new Date(date);
        if (date.constructor.name.toLowerCase() !== 'date') {
            return 'Invalid date';
        }

        const current = new Date().getTime();
        const previous = date.getTime();
        const elapsed = current - previous;

        if (elapsed < msPerMinute) {
            const val = Math.round(elapsed / 1000);
            return val < 20 ? 'just now' : val + ' seconds ago';
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + ' minutes ago';
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + ' hours ago';
        } else if (elapsed < msPerMonth) {
            return Math.round(elapsed / msPerDay) + ' days ago';
        } else if (elapsed < msPerYear) {
            return Math.round(elapsed / msPerMonth) + ' months ago';
        } else {
            return Math.round(elapsed / msPerYear) + ' years ago';
        }
    }

    private formatUserName(userName: string): string {
        let outpгtUserName = userName;
        const userWords = words(userName);
        if (userWords.length === 1) {
            outpгtUserName = upperFirst(userName);
        } else {
            const first = userWords[0].toLowerCase();
            const NAME_PREFIXES = ['van', 'van de', 'ter', 'van der', 'de'];

            if (userWords.length === 2) {
                each(NAME_PREFIXES, prefix => {
                    if (prefix === first) {
                        outpгtUserName = this.formatUserParts(userName, prefix, userWords, 1);
                        return false;
                    }
                });
            } else if (userWords.length > 2) {
                const two = first + ' ' + userWords[1].toLowerCase();
                each(NAME_PREFIXES, prefix => {
                    if (prefix === two) {
                        outpгtUserName = this.formatUserParts(userName, prefix, userWords, 2);
                        return false;
                    }
                });
            }
        }

        return outpгtUserName;
    }

    private formatUserParts(inputUserName: string, prefix: string, wordsToChange: string[], shiftCount: number): string {
        let count = 0;
        while (count < shiftCount) {
            wordsToChange.shift();
            count++;
        }
        wordsToChange[0] = upperFirst(wordsToChange[0]);
        wordsToChange.unshift(prefix);
        const name = wordsToChange.join(' ');
        if (endsWith(inputUserName, ' ')) {
            return name + ' ';
        }
        return name;
    }

    private capitalizeTextFirstLetter(str: string): string {
        const isFirstCharacterUppercase = str.substr(0, 1).toUpperCase() === str.substr(0, 1);

        return isFirstCharacterUppercase ? str : str.substr(0, 1).toUpperCase() + str.substr(1);
    }

    public capitalizeFirstLetter(str: string, isName: boolean): string {
        if (str) {
            return isName ? this.formatUserName(str) : this.capitalizeTextFirstLetter(str);
        } else {
            return null;
        }
    }
}
