import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

export interface BottomSheetActionItem {
  id: string;
  title: string;
  subtitle?: string;
}

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit {
  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetActionItem[],
  ) { }

  ngOnInit() { }

  public optionSelected(evt: MouseEvent, option: BottomSheetActionItem): void {
    evt.preventDefault();
    this.bottomSheetRef.dismiss(option);
  }
}
