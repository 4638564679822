import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Company } from '@models/commons';
import { FirestoreService } from '../firestore/firestore.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private colRef = 'companies';

  constructor(
    private afDb: FirestoreService,
    private localStore: LocalStorageService,
  ) { }

  public getCurrentCompanyId(): string {
    return this.localStore.getItemSync('companyId');
  }

  public getCurrentCompany(): Observable<Company> {
    return this.getCompanyById(this.getCurrentCompanyId());
  }

  public getCompaniesByIds(ids: string[]): Observable<Company[]> {
    return combineLatest(ids.map(id => this.getCompanyById(id)));
  }

  public getCompanyById(id: string): Observable<Company> {
    return this.afDb.docWithId$(`${this.colRef}/${id}`);
  }
}
