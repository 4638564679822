import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LabelService } from '@core/services/label/label.service';
import { PwaService } from '@core/services/pwa/pwa.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public labels = this.labelService.defaultProvider();

  constructor(
    private pwaService: PwaService,
    private titleService: Title,
    private labelService: LabelService,
  ) { }

  async ngOnInit() {
    this.pwaService.init();

    this.labels = await this.labelService.getLabels('app-root').pipe(take(1)).toPromise();
    this.titleService.setTitle(this.labels.find_company);
  }
}
