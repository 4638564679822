import { Injectable } from '@angular/core';
import { IModalDialogStackItem } from '@models/interface';
import { MatDialogRef } from '@angular/material/dialog';
import { findIndex, find } from 'lodash';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { LabelService } from '../label/label.service';
import { UtilitiesService } from '../utilities/utilities.service';

@Injectable({
    providedIn: 'root'
})
export class ModalNavigatorService {
    private activeStackItems: IModalDialogStackItem[] = [];
    private labels: ILabelsProvider;

    constructor(
        private labelService: LabelService,
        private utilitiesService: UtilitiesService
    ) {
        this.labelService.getLabels('global-labels').subscribe(res => {
            this.labels = res;
        }, e => this.utilitiesService.errHandler(e));
    }

    public push(id: string, ref: MatDialogRef<any>): void {
        setTimeout(() => {
            this.activeStackItems.push({
                id,
                dialogRef: ref
            });
        }, 10);
    }

    public pop(response?: any): any {
        const item = this.activeStackItems[this.activeStackItems.length - 1];

        if (item && item.unsavedChanges) {
            const sure = confirm(this.labels.unsaved_changes_prompt_text);
            if (sure) {
                item.unsavedChanges = false;
                return this.pop();
            } else {
                return null;
            }
        } else {
            item.dialogRef.close(response);
            return this.activeStackItems.pop();
        }
    }

    public canCloseModal(modalId: string): boolean {
        const item: IModalDialogStackItem = find(this.activeStackItems, { id: modalId });

        return !item.unsavedChanges;
    }

    public updateItemState(modalId: string, status: boolean): void {
        const index: number = findIndex(this.activeStackItems, { id: modalId });

        if (index < 0) {
            throw Error(`Invalid modalId: ${modalId}`);
        }

        this.activeStackItems[index].unsavedChanges = status;
    }

    public closeAll() {
        // check if there's unsaved changes and close if not
        while (!this.isEmpty()) {
            const poppedItem = this.pop();

            if (!poppedItem) {
                break;
            }
        }
    }

    public size(): number {
        return this.activeStackItems.length;
    }

    public isEmpty(): boolean {
        return this.size() === 0;
    }
}
