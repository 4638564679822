import { Directive, HostListener, Output, EventEmitter, OnInit } from '@angular/core';

export interface IScrollEvent {
    position: number;
    upAction: boolean;
    downAction: boolean;
}

@Directive({
    selector: '[appScroll]'
})
export class AppScrollDirective implements OnInit {
    private relativePosition = 0;

    @Output() public scrollEvent = new EventEmitter<IScrollEvent>();

    @HostListener('scroll', ['$event'])
    onScroll($event: Event) {
        this.elementScrollEvent($event);
    }

    ngOnInit() { }

    protected elementScrollEvent($event: Event) {
        const target = $event.target as HTMLElement;

        const isUp: number = target.scrollTop - this.relativePosition;
        const evt: IScrollEvent = {
            position: target.scrollTop,
            upAction: isUp <= 0,
            downAction: isUp > 0
        };

        this.scrollEvent.emit(evt);
        this.relativePosition = target.scrollTop;
    }
}
