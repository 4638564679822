import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@core/services/user/user.service';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoProfilePublishedGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  public async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const isPublished: boolean = await this.userService.getCurrentUser().pipe(
      take(1),
      map(user => {
        return user && user.published;
      })
    ).toPromise();

    if (!isPublished) {
      this.router.navigateByUrl('/');
    }

    return isPublished;
  }
}
