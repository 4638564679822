<div *ngFor="let item of items" class="each_item p-2" fxLayout="column" fxLayoutGap="4px">
    <div>
        <div class="item_body m-2 p-2" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="flex-start"
            *ngIf="item.eventId && eventMap[item.eventId] as event">
            <div class="event-item" fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="row" fxLayoutGap="8px" fxFlex="stretch">
                    <div fxLayout="column" *ngIf="event.thumbnail" style="margin-right: 10px;">
                        <img [src]="event.thumbnail" width="auto" height="50px" class="cursor_pointer"
                            (click)="goToEvent(event.id)">
                    </div>
                    <div fxLayout="column">
                        <div class="header-title">{{event.title}}</div>
                        <div class="mat-body">{{event.from | date:'mediumDate'}}</div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="end end">
                <button mat-button color="primary" (click)="goToEvent(event.id)">
                    {{labels.show_event}}
                </button>
            </div>
        </div>

        <div class="item_body m-2 p-2" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="flex-start">
            <div fxFlex="stretch">
                <div fxLayout="row" fxLayoutAlign=" start center">
                    <app-thumbnail class="cursor_pointer" (click)="gotoPublicProfile(item)" [image]="user.photoUrl"
                        *ngIf="usersMap[item.userId]?.user as user"></app-thumbnail>

                    <div class="carriage_returns" fxLayout="column" fxLayoutGap="10px">
                        <span>{{getItemText(item)}}</span>
                        <div fxLayout="row" class="show_more_link text-primary"
                            (click)="expandedMap[item.id] = !expandedMap[item.id]"
                            *ngIf="item.message.length > summarySize">
                            <span>{{expandedMap[item.id] ? labels.show_less : labels.show_more}}</span>
                        </div>
                    </div>
                </div>
                <div fxLayoutAlign="space-between" *ngIf="currentUserId !== item.userId">
                    <button mat-button color="primary" (click)="gotoPublicProfile(item)">
                        {{labels.show_profile}}
                    </button>
                    <button mat-button color="primary" (click)="openReactionDialog(item)">
                        {{labels.react}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="item_footer " fxLayout="row" fxLayoutGap="1px" fxFlexAlign="start center">
        <div fxLayout="row" fxLayoutGap="1px" *ngIf="currentUserId === item.userId && pageView === 'my'">
            <button mat-button (click)="deleteItem(item)">{{labels.delete}}</button>
            <button mat-button (click)="editNoticeBoard(item)">{{labels.edit}}</button>
        </div>

        <div fxFlex="stretch" fxLayout="row" fxLayoutAlign="end start">
            <span class="fs-0-7 text-muted">{{timeAgoMap[item.id]}}</span>
        </div>
    </div>
</div>