import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { NoAuthGuard } from './no-auth/no-auth.guard';
import { NoProfilePublishedGuard } from './no-profile-published/no-profile-published.guard';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [
    AuthGuard,
    NoAuthGuard,
    NoProfilePublishedGuard
  ]
})
export class RouteGuardsModule { }
