import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PostcodeAddress } from './address.model';
import { environment } from 'src/environments/environment';
import { GeoPoint } from '@models/commons';
import firestore from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private http: HttpClient
    ) { }

  public getAddress(postcode: string, number: number): Promise<PostcodeAddress> {
    const options: any = {
      headers: {
        'x-api-key': environment.LOCATION_API_KEY,
        'accept': 'application/hal+json'
      }
    };

    return <Promise<PostcodeAddress>>this.http.get(
      `https://api.postcodeapi.nu/v2/addresses/?postcode=${postcode}&number=${number}`,
      options
    ).pipe(take(1)).toPromise().then((data: any) => <PostcodeAddress>data);
  }

  public getGeoLocation(postalcode: string, housenumber: number): Promise<GeoPoint> {
    return new Promise(async (resolve, reject) => {
      if (postalcode.length === 6 && housenumber > 0) {
        const data: PostcodeAddress = await this.getAddress(postalcode, housenumber);

        if (data && data._embedded && data._embedded.addresses[0]) {
          const coordinates: number[] = data._embedded.addresses[0].geo.center.wgs84.coordinates;
          const geo: GeoPoint = new firestore.firestore.GeoPoint(coordinates[0], coordinates[1]);

          resolve(geo);
        } else {
          reject('location_not_found');
        }
      } else {
        reject('invalid_postal_code_or_number');
      }
    });
  }
}
