import { take } from 'rxjs/operators';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/portal';
import { PromptDialogueComponent, PromptInputConfig } from '@shared/entry-components/prompt-dialogue/prompt-dialogue.component';
import { ConfirmDialogueComponent, ConfirmDialogConfig } from '@shared/entry-components/confirm-dialogue/confirm-dialogue.component';
import { AlertDialogueComponent } from '@shared/entry-components/alert-dialogue/alert-dialogue.component';
import { LayoutService } from '../layout/layout.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { ImageHandlerModalComponent, ImageHandlerConfig, ImageHandlerOutput } from '@shared/entry-components/image-handler-modal/image-handler-modal.component';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { BottomSheetComponent, BottomSheetActionItem } from '@shared/entry-components/bottom-sheet/bottom-sheet.component';

export interface IShowDialogOptions {
    disableClose?: boolean;
    autoFocus?: boolean;
    openInFullHeight?: boolean;
    width?: string;
    panelClass?: string;
    height?: string;
    data: any;
    modalId?: string;
}

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private isMobile: boolean;

    constructor(
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private layoutService: LayoutService,
        private utilitiesService: UtilitiesService,
        private bottomSheet: MatBottomSheet
    ) {
        // screen matches
        this.layoutService.onScreenMatches('xs').subscribe(status => {
            this.isMobile = status;
        }, e => this.utilitiesService.errHandler(e));
    }

    public showToast(message: string, actionText?: string, duration?: number): Promise<boolean> {
        return new Promise(resolve => {
            const snackbarRef = this.snackBar.open(message, actionText, {
                duration: duration || 4000,
                panelClass: ['toast-custom-cl']
            });

            snackbarRef.afterDismissed().subscribe((b) => {
                resolve(b.dismissedByAction);
            });
        });
    }

    public showBottomSheet(options: BottomSheetActionItem[]): Promise<BottomSheetActionItem> {
        const config: MatBottomSheetConfig<BottomSheetActionItem[]> = {
            data: options
        };

        const ref = this.bottomSheet.open(BottomSheetComponent, config);
        return ref.afterDismissed().pipe(take(1)).toPromise();
    }

    public showDialogue<T>(dialogComponent: ComponentType<any> | TemplateRef<any>, options: IShowDialogOptions): Promise<T> {
        const height = options.openInFullHeight
            ? this.isMobile ? '100%' : '90vh'
            : (options.height || 'auto');
        const width = this.isMobile && !options.openInFullHeight
            ? '300px' : (options.width || '750px');

        const dialogRef = this.dialog.open(dialogComponent, {
            width, height,
            maxHeight: this.isMobile ? '100%' : '90vh',
            maxWidth: this.isMobile ? '100%' : '90vw',
            disableClose: options.disableClose,
            autoFocus: options.autoFocus,
            panelClass: options.panelClass,
            data: options.modalId
                ? Object.assign({}, options.data, { modalId: options.modalId })
                : options.data
        });

        return dialogRef.afterClosed().pipe(take(1)).toPromise();
    }

    public handleImage(c?: ImageHandlerConfig): Promise<ImageHandlerOutput> {
        const config: ImageHandlerConfig = Object.assign({}, c || {});

        const opts: IShowDialogOptions = {
            data: config,
            openInFullHeight: true,
            disableClose: true
        };

        return this.showDialogue(ImageHandlerModalComponent, opts);
    }

    // just like native javascript prompt function
    public prompt(config: PromptInputConfig): Promise<string> {
        const options: IShowDialogOptions = {
            data: config,
            width: '400px'
        };

        return this.showDialogue(PromptDialogueComponent, options);
    }

    // just like javascript confirm function
    public confirm(config: ConfirmDialogConfig): Promise<boolean> {
        return this.showDialogue(ConfirmDialogueComponent, {
            width: '400px',
            data: config
        });
    }

    // just like native javascript alert function
    public alert(title: string, message: string) {
        this.showDialogue(AlertDialogueComponent, {
            width: '400px',
            data: {
                message,
                title,
                ok: 'OK'
            },
            disableClose: true
        });
    }
}
