import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ILabelsProvider } from '@anchor-solutions-nl/translator-as';
import { Sub } from '@core/subscriptions';
import { LabelService } from '@core/services/label/label.service';
import { UtilitiesService } from '@core/services/utilities/utilities.service';

export interface ConfirmDialogConfig {
    title?: string;
    message: string;
    confirmBtn?: string;
    cancelBtn?: string;
}

@Component({
    selector: 'app-confirm-dialogue',
    styleUrls: ['./confirm-dialogue.component.css'],
    templateUrl: './confirm-dialogue.component.html',
})
export class ConfirmDialogueComponent implements OnInit, OnDestroy {
    public labels: ILabelsProvider = this.labelService.defaultProvider();
    public config: ConfirmDialogConfig;

    private sub: Sub = new Sub();

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogueComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogConfig,
        private labelService: LabelService,
        private utilitiesService: UtilitiesService
    ) { }

    ngOnInit() {
        this.sub.add(
            this.labelService.getLabels('app-confirm-dialogue').subscribe(lbs => {
                this.labels = lbs;

                this.config = Object.assign({}, {
                    confirmBtn: lbs.confirm,
                    title: lbs.confirm_action,
                    cancelBtn: lbs.cancel
                }, this.data);
            }, e => this.utilitiesService.errHandler(e))
        );
    }

    continue() {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
