<mat-table #table [dataSource]="matTableDataSource">
    <ng-container [matColumnDef]="property" *ngFor="let property of displayProperties">
        <ng-container [ngSwitch]="property">
            <ng-container *ngSwitchCase="'select'">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [disabled]="!matTableDataSource.filteredData?.length" [checked]="isMasterToggleChecked()"
                        [indeterminate]="isMasterToggleIndeterminate()">
                    </mat-checkbox>
                </mat-header-cell>

                <mat-cell *matCellDef="let row"
                    [ngClass]="[row?._metadata && row._metadata.disabled ? 'disabled_element' : '']">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="handleRowCheckbox(row)"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="configOptions.displayHeaders">
                        {{ configOptions.headers ? configOptions.headers[property] : property || false }}
                    </span>
                </mat-header-cell>

                <mat-cell *matCellDef="let row" (click)="handleRowSelect(row)" [ngClass]="[property === 'status' && configOptions.highlightStatuses ? 'status-' + row[property] : '',
                    row?._metadata && row._metadata.disabled ? 'disabled_element' : '']">
                    <span *ngIf="property === configOptions.propertyWithImage">
                        <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                            <app-thumbnail [image]="row['photoUrl']"> </app-thumbnail>
                            <ng-container
                                *ngTemplateOutlet="tableFieldDisplay; context:{ property: property, row: row }">
                            </ng-container>
                        </span>
                    </span>
                    <span *ngIf="property !== configOptions.propertyWithImage">
                        <ng-container *ngTemplateOutlet="tableFieldDisplay; context:{ property: property, row: row }">
                        </ng-container>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'_actions_'">
                <mat-header-cell *matHeaderCellDef fxFlex="30px">
                </mat-header-cell>

                <mat-cell *matCellDef="let row" fxLayoutAlign="center center" fxFlex="30px">
                    <button mat-icon-button [matMenuTriggerFor]="controls">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #controls="matMenu">
                        <button (click)="selectRowAction(option, row)" mat-menu-item *ngFor="let option of rowOptions">
                            <mat-icon>{{option.icon}}</mat-icon>
                            <span>{{option.title}}</span>
                        </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <ng-container *ngSwitchCase="'_next_icon_'">
                <mat-header-cell *matHeaderCellDef fxFlex="25px">
                </mat-header-cell>

                <mat-cell *matCellDef="let row" fxLayoutAlign="center center" fxLayout="row" fxFlex="25px">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </mat-cell>
            </ng-container>
        </ng-container>
    </ng-container>

    <mat-header-row [class.display-none]="!configOptions.displayHeaders" *matHeaderRowDef="displayProperties">
    </mat-header-row>

    <mat-row *matRowDef="let row; columns: displayProperties;" [class.active]="selection.isSelected(row)"
        class="user-row">
    </mat-row>
</mat-table>

<div class="footer" *ngIf="configOptions.actionButtonLabel">
    <button [disabled]="selection.selected.length === 0" mat-raised-button color="primary"
        (click)="actionButtonClick()">
        {{configOptions.actionButtonLabel}}
    </button>
</div>

<ng-template #tableFieldDisplay let-row="row" let-property="property">
    <div *ngIf="row[property]">
        <span *ngIf="row[property].subtitle" fxLayout="column" fxLayoutGap="8px">
            <span class="cell_title">{{row[property].title}}</span>
            <span class="cell_subtitle">{{row[property].subtitle}}</span>
        </span>

        <span *ngIf="!row[property].subtitle">
            {{row[property].title || row[property]}}
        </span>
    </div>
</ng-template>