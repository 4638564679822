import { Injectable } from '@angular/core';
import {
    ITranslatorConfigOptions,
    TranslatorClientService,
    ILabelsProvider,
    Language,
    IObjectMap
} from '@anchor-solutions-nl/translator-as';
import { environment } from 'src/environments/environment';
import { Observable, from } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LabelService {
    private translatorService: TranslatorClientService;

    constructor() {
        const options: ITranslatorConfigOptions = {
            appId: environment.labelsAppID,
            sync: true,
            labelStore: localStorage
        };

        this.translatorService = new TranslatorClientService(options);
    }

    public init(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await this.translatorService.init();

                try {
                    const language = JSON.parse(localStorage.getItem('user_language'));
                    if (language) {
                        this.setLanguge(
                            language
                        );
                    }
                } catch (e) { }

                resolve();
            } catch (e) {
                console.log(e);
                reject();
            }
        });
    }

    // gets default Ilabels provider for labels
    public defaultProvider(): ILabelsProvider {
        return {
            // tslint:disable-next-line: variable-name
            _translate: (val: string, _varMap?: IObjectMap<any>) => val
        };
    }

    public getLabels(segmentId: string): Observable<ILabelsProvider> {
        return from(this.translatorService.getlabels(segmentId)).pipe(
            pluck('data')
        );
    }

    getSupportedlanguages(): Language[] {
        return this.translatorService.getAppLanguages();
    }

    setLanguge(language: Language) {
        this.translatorService.setDefaultLanguage(language.symbol);
    }
}
