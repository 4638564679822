import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { filter, take, map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage/local-storage.service';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public authUser: firebase.User;

  private authState$: BehaviorSubject<firebase.User> = new BehaviorSubject(undefined);
  private authSubs: Subscription[] = [];

  public constructor(
    private afAuth: AngularFireAuth,
    private zone: NgZone,
    private localStore: LocalStorageService
  ) {
    this.afAuth.onAuthStateChanged(async (user) => {
      this.authUser = user;
      this.zone.run(() => this.authState$.next(user));
    });
  }

  public addAuthSubscriptions(...subs: Subscription[]): void {
    subs.forEach(s => this.authSubs.push(s));
  }

  public isProperAuthStorage(): boolean {
    return !!(this.localStore.getItemSync('userId')
      && this.localStore.getItemSync('companyId')
      && this.localStore.getItemSync('serviceId'));
  }

  public isLoggedIn(): Promise<boolean> {
    return this.getAuthUser().pipe(
      take(1),
      map(user => !!user)
    ).toPromise();
  }

  public getAuthUser(): Observable<firebase.User> {
    return this.authState$.pipe(
      filter(user => user !== undefined)
    );
  }

  public register(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  public login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public logout(): Promise<void> {
    // clears every active subscription
    this.authSubs.forEach(s => s.unsubscribe());

    return this.afAuth.signOut();
  }

  public watchEmailVerification(): Observable<void> {
    return new Observable(observer => {
      this.afAuth.currentUser.then(async (user) => {
        const interval = setInterval(async () => {
          await user.reload();
          if (user.emailVerified) {
            clearInterval(interval);
            observer.next();
          }
        }, 2000);
      });
    });
  }

  public sendVerificationEmail(waitForComplete?: boolean): Observable<any> {
    return new Observable((observer) => {
      this.afAuth.currentUser.then(async (user) => {
        user.sendEmailVerification().then(() => {
          if (waitForComplete) {
            this.watchEmailVerification().subscribe(() => {
              observer.next();
            });
          } else {
            observer.next();
          }
        }).catch(err => {
          throw Error(err);
        });
      });
    });
  }

  public resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  private getPasswordCredential(email: string, password: string): firebase.auth.AuthCredential {
    return firebase.auth.EmailAuthProvider.credential(email, password);
  }

  public async changePassword(email: string, oldpass: string, newpass: string): Promise<void> {
    const credential = this.getPasswordCredential(email, oldpass);

    await this.authUser.reauthenticateWithCredential(credential);

    return this.authUser.updatePassword(newpass);
  }
}
