import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { matModules } from './mat-shared';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ModalComponent } from './components/modal/modal.component';
import { AlertDialogueComponent } from './entry-components/alert-dialogue/alert-dialogue.component';
import { ConfirmDialogueComponent } from './entry-components/confirm-dialogue/confirm-dialogue.component';
import { PromptDialogueComponent } from './entry-components/prompt-dialogue/prompt-dialogue.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ImageHandlerModalComponent } from './entry-components/image-handler-modal/image-handler-modal.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { ProgressComponent } from './components/progress/progress.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { SelectProfileComponent } from './entry-components/select-profile/select-profile.component';
import { AppScrollDirective } from './directives/scroll.directive';
import { BottomSheetComponent } from './entry-components/bottom-sheet/bottom-sheet.component';
import { NoticeBoardItemsComponent } from './components/notice-board-items/notice-board-items.component';
import { PhoneFieldComponent } from './components/phone-field/phone-field.component';
import { ImagePreviewComponent } from './entry-components/image-preview/image-preview.component';
import { ReactionDialogComponent } from './reaction-dialog/reaction-dialog.component';

const declarations = [
  AlertDialogueComponent,
  ConfirmDialogueComponent,
  DataTableComponent,
  ImageHandlerModalComponent,
  ModalComponent,
  ProgressComponent,
  PromptDialogueComponent,
  ThumbnailComponent,
  SelectProfileComponent,
  BottomSheetComponent,
  NoticeBoardItemsComponent,
  PhoneFieldComponent,
  ImagePreviewComponent,

  // pipes
  TimestampPipe,

  // directives
  AppScrollDirective,
]

@NgModule({
  declarations: [
    ...declarations,
    ReactionDialogComponent,
  ],
  imports: [
    ...matModules,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ImageCropperModule
  ],
  exports: [
    ...matModules,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ...declarations,
  ],
  entryComponents: [
    AlertDialogueComponent,
    ConfirmDialogueComponent,
    ImageHandlerModalComponent,
    PromptDialogueComponent,
    SelectProfileComponent,
    BottomSheetComponent
  ],
  providers: []
})
export class SharedModule { }
